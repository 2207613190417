import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { PickupsModule } from '../pickups/pickups.module';
import { SharedModule } from '../shared/shared.module';
import { DashboardService } from '../services/dashboard.service';


@NgModule({
  declarations: [DashboardComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SharedModule,
    PickupsModule
  ],
  providers: [
    DashboardService
  ]
})
export class DashboardModule { }

