import { Component, OnInit, Input } from '@angular/core';
import { LenderPayoutStatus, PaymentStatus } from 'src/class/borrow-request.model';
import { UsersService } from 'src/app/services/users.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { LendiUserRecord } from 'src/class/user.model';
import { BorrowRequestRecord } from 'projects/lendi-business/src/public-api';

@Component({
  selector: 'app-lending-card',
  templateUrl: './lending-card.component.html',
  styleUrls: ['./lending-card.component.css']
})
export class LendingCardComponent implements OnInit {

  @Input() borrowRequest: BorrowRequestRecord;
  productBorrower: LendiUserRecord;
  spinnerName: string;
  cardClass: string;

  constructor(private $dbUser: UsersService, private spinner: SpinnerService) { }

  ngOnInit() {
    this.cardClass = this.setCardClass();
    this.spinnerName = this.borrowRequest.id;
    this.spinner.show(this.spinnerName);
    this.$dbUser.ref.doc(this.borrowRequest.uid).get().toPromise().then((userRecord) => {
      this.productBorrower = userRecord.data() as LendiUserRecord;
      this.spinner.hide(this.spinnerName);
    });
  }

  setCardClass() {
    switch(this.borrowRequest.payment.status) {
      case PaymentStatus.Pending:
        return 'spending-transaction';
      case PaymentStatus.Successful:
      default:
        return 'cash-in-transaction';

    }
  }

}
