import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SecurityDepositRoutingModule } from './security-deposit-routing.module';
import { SecurityDepositListComponent } from './security-deposit-list/security-deposit-list.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [SecurityDepositListComponent],
  imports: [
    CommonModule,
    SecurityDepositRoutingModule,
    SharedModule
  ]
})
export class SecurityDepositModule { }
