import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SpinnerService } from 'src/app/services/spinner.service';
import { EmployeesService } from 'src/app/services/employees.service';
import { LendiEmployeeRecord, LendiUserRecord } from 'projects/lendi-business/src/public-api';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';

declare var $: any;

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.css']
})
export class EmployeeListComponent implements OnInit, OnDestroy {


  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  spinnerName = 'EmployeeListComponent';
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  userItems: LendiEmployeeRecord[] = [];
  displayFilterValue = null;

  constructor(
    private $db: EmployeesService,
    private spinner: SpinnerService,
    private router: Router) {}

  ngOnInit() {

    $.fn['dataTable'].ext.search.push((settings, data: LendiUserRecord, dataIndex) => {
      if (this.displayFilterValue === null) {
        return true;
      } else if (this.displayFilterValue) {
        return !this.userItems.find(u => u.id === data[0]).isDeleted;
      } else {
        return this.userItems.find(u => u.id === data[0]).isDeleted;
      }
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };

    this.spinner.show(this.spinnerName);
    this.$db.refActive.valueChanges().pipe(first()).subscribe(users => {
      this.userItems = users as any;
      this.dtTrigger.next();
      this.spinner.hide(this.spinnerName);
    });
  }

  ngOnDestroy() {
    $.fn['dataTable'].ext.search.pop();
    this.dtTrigger.unsubscribe();
  }

  onViewEdit(lendiEmployeeRecord: LendiEmployeeRecord) {
    this.router.navigate(['employee-database', lendiEmployeeRecord.id, 'detail'], {
      state: {
        item: lendiEmployeeRecord
      }
    });
  }

  displayFilterChanged($event) {
    this.displayFilterValue = $event;
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

}
