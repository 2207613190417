import { Pipe, PipeTransform } from '@angular/core';
import { ItemTrackerStatus } from 'src/class/borrow-request.model';

@Pipe({
  name: 'itemTrackerStatus'
})
export class ItemTrackerStatusPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    switch(value) {
      case ItemTrackerStatus.Processing:
        return 'Processing';
      case ItemTrackerStatus.AssignedRiderFromLendertoBorrower:
        return 'Assign to Rider';
      case ItemTrackerStatus.RiderOnTheWayToLender:
        return 'Rider on the way to lender';
      case ItemTrackerStatus.FailedPickUpFromLender:
        return 'Failed pickup from lender';
      case ItemTrackerStatus.PickedUpFromLender:
        return 'Pickup successful from lender. On transit to borrower.';
      case ItemTrackerStatus.FailedDeliveryToBorrower:
        return 'Failed delivery to borrower';
      case ItemTrackerStatus.DeliverySuccessfulToBorrower:
        return 'Delivery successful to borrower';
      case ItemTrackerStatus.AssignedRiderFromBorrowerToLender:
        return 'Assign to Rider';
      case ItemTrackerStatus.RiderOnTheWayToBorrower:
        return 'Rider on the way to borrower';
      case ItemTrackerStatus.FailedPickUpFromBorrower:
        return 'Failed pickup from borrower';
      case ItemTrackerStatus.PickedUpFromBorrower:
        return 'Pickup successful from borrower. On transit to lender.';
      case ItemTrackerStatus.FailedDeliveryToLender:
        return 'Failed delivery to lender';
      case ItemTrackerStatus.DeliverySuccessfulToLender:
        return 'Delivery successful to lender';
      case ItemTrackerStatus.TransactionCancelled:
        return 'Transaction Cancelled';
      case ItemTrackerStatus.Pending:
      default:
        return 'Pending';
    }
  }

}
