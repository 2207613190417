import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ItemVerificationRoutingModule } from './item-verification-routing.module';
import { ItemVerificationListComponent } from './item-verification-list/item-verification-list.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [ItemVerificationListComponent],
  imports: [
    CommonModule,
    ItemVerificationRoutingModule,
    SharedModule
  ]
})
export class ItemVerificationModule { }
