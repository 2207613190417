import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'projects/lendi-business/src/public-api';
import { AngularFireStorage } from '@angular/fire/storage';
import { UploadTask } from '@angular/fire/storage/interfaces';
import { LendiPhoto } from 'src/class/image.model';

@Injectable({
  providedIn: 'root'
})
export class EmployeesService {

  userProfileUploadTask: UploadTask;

  constructor(private $db: AngularFirestore, private $storage: AngularFireStorage) { }

  get ref() {
    return this.$db.collection(environment.collections.employeeProfiles);
  }

  get refActive() {
    return this.$db.collection(environment.collections.employeeProfiles, ref => ref.where('isDeleted', '==', false));
  }

  uploadProfilePhoto(id: string, upload: LendiPhoto) {
    const uploadAndUpdate = new Promise((resolve, reject) => {
      this.userProfileUploadTask = this.$storage.storage
        .ref(`${environment.storagePath.employeeProfilePhotos}/${id}/${upload.file.name}`)
        .put(upload.file);

      return this.userProfileUploadTask.then(
        (res) => {
          return this.$storage.storage.ref(res.metadata.fullPath).getDownloadURL().then(url => {
            return this.ref.doc(id).update({profilePhoto: { source: url }}).then(updateResponse => {
              resolve(url);
              return updateResponse;
            });
          });
        }).catch(error => {
          console.error(error);
          reject(error);
          return false;
        });
    });

    return uploadAndUpdate;
  }
}
