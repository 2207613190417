import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EmployeesService } from 'src/app/services/employees.service';
import { LendiEmployeeRecord } from 'projects/lendi-business/src/public-api';
import { setErrorMessage } from 'src/app/shared/validators/validation-messages';
import Swal from 'sweetalert2';
import * as firebase from 'firebase';
import { PageMode } from 'src/class/page-mode.enum';
import { LendiPhoto } from 'src/class/image.model';
import { CivilStatus } from 'projects/lendi-business/src/public-api';
import { PlaceholderAppReferenceService } from 'src/app/services/placeholder-app-reference.service';
import { compare, City, Province } from 'src/class/app-settings.model';
import { environment } from 'projects/lendi-business/src/public-api';

declare var $: any;

@Component({
  selector: 'app-employee-detail',
  templateUrl: './employee-detail.component.html',
  styleUrls: ['./employee-detail.component.css']
})
export class EmployeeDetailComponent implements OnInit {

  spinnerName = 'EmployeeDetailComponent';
  lendiEmployeeRecord: LendiEmployeeRecord;
  lendiEmployeeForm: FormGroup = this.fb.group({
    profile: this.fb.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      middlename: [''],
      gender: ['Male', [Validators.required]],
      civilStatus: [CivilStatus.Single, [Validators.required]],
      birthdate: ['', [Validators.required]],
      mobile: ['', [Validators.required]],
    }),
    profileAddress: this.fb.group({
      streetAddress: ['', [Validators.required, Validators.maxLength(255)]],
      barangay: ['', [Validators.required, Validators.maxLength(255)]],
      region: [null, [Validators.required, Validators.maxLength(255)]],
      city: [null, [Validators.required, Validators.maxLength(255)]],
      postalCode: ['', [Validators.required, Validators.maxLength(4)]]
    }),
    accountDetails: this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    })
  });
  formErrors: { [key: string]: string };
  profilePhotoUrl: string;
  selectedFile: File = null;
  pageMode = PageMode.New;
  provinces: Province[] =  environment.provinces;
  cities: City[] = environment.cities;

  constructor(
    private fb: FormBuilder,
    private spinner: SpinnerService,
    private router: Router,
    private activeRouter: ActivatedRoute,
    private $db: EmployeesService,
    private $dbDummyApp: PlaceholderAppReferenceService
  ) { }

  get city() {
    return this.lendiEmployeeForm.get('profileAddress.city');
  }
  get region() {
    return this.lendiEmployeeForm.get('profileAddress.region');
  }

  ngOnInit() {
    this.cities = this.cities.sort((a: City, b: City) => compare(a.description, b.description));
    this.setUpForm();
    this.setErrorSubscriptions();
    this.setDefaultLendiEmployeeRecord();
  }

  setUpForm() {
    $('#birthdate').datepicker({
      uiLibrary: 'bootstrap',
      change: e => {
        this.lendiEmployeeForm.get('profile.birthdate').setValue($(e.target).val());
      }
    });

    this.formErrors = {
      firstname: '',
      lastname: '',
      birthdate: '',
      mobile: '',
      gender: '',
      civilStatus: '',
      streetAddress: '',
      barangay: '',
      region: '',
      city: '',
      postalCode: '',
      email: '',
      password: ''
    };
  }

  setDefaultLendiEmployeeRecord() {
    const idFromRoute = this.activeRouter.snapshot.paramMap.get('id');
    this.lendiEmployeeRecord = window.history.state.item;

    if (this.lendiEmployeeRecord === undefined && idFromRoute === null) {
      this.lendiEmployeeRecord = {
        id: this.$db.ref.ref.doc().id,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        isActive: true,
        isDeleted: false,
        profile: null,
        profileAddress: null,
        profilePhoto: null,
        accountDetails: null
      };
      this.pageMode = PageMode.New;
      return;
    }

    this.pageMode = PageMode.Edit;
    if (this.lendiEmployeeRecord === undefined && idFromRoute !== null) {
      this.spinner.show(this.spinnerName);
      this.$db.ref.doc(idFromRoute).get().toPromise().then(res => {
        this.lendiEmployeeRecord = res.data() as LendiEmployeeRecord;
        this.setFormValues(this.lendiEmployeeRecord);
      }).catch(err => {
        console.error(err);
        this.back();
      }).finally(() => {
        this.spinner.hide(this.spinnerName);
      });
    } else {
      this.setFormValues(this.lendiEmployeeRecord);
    }
  }

  setProfilePhoto() {
    if (this.lendiEmployeeRecord.profilePhoto) {
      if (this.lendiEmployeeRecord.profilePhoto.source) {
        this.profilePhotoUrl = this.lendiEmployeeRecord.profilePhoto.source;
        return;
      }
    }

    this.profilePhotoUrl = undefined;
  }

  setFormValues(lendiEmployeeRecord: LendiEmployeeRecord) {
    this.lendiEmployeeForm.get('profile').setValue(lendiEmployeeRecord.profile);
    this.lendiEmployeeForm.get('profileAddress').setValue(lendiEmployeeRecord.profileAddress);
    this.lendiEmployeeForm.get('accountDetails').setValue(lendiEmployeeRecord.accountDetails);
    this.setProfilePhoto();

    if (this.pageMode !== PageMode.New) {
      this.lendiEmployeeForm.get('accountDetails').disable();
    }
  }

  setErrorSubscriptions() {
    const profileControls = Object.keys((this.lendiEmployeeForm.get('profile') as FormGroup).controls);
    const profileAddressControls = Object.keys((this.lendiEmployeeForm.get('profileAddress') as FormGroup).controls);
    const accountDetailsControls = Object.keys((this.lendiEmployeeForm.get('accountDetails') as FormGroup).controls);

    Object.keys(this.formErrors).map((key) => {
      const inProfile = profileControls.find(k => k === key) ? 'profile' : '';
      const inProfileAddress = inProfile === '' && profileAddressControls.find(k => k === key) ? 'profileAddress' : '';
      const inAccountDetails = inProfile === '' && inProfileAddress === '' && accountDetailsControls.find(k => k === key) ? 'accountDetails' : '';
      const formGroupKey = inProfile + inProfileAddress + inAccountDetails;

      this.lendiEmployeeForm.get(formGroupKey + '.' + key).valueChanges.subscribe(value => {
        this.formErrors[key] = setErrorMessage(this.lendiEmployeeForm.get(formGroupKey + '.' + key), key);
      });
    });
  }

  onSubmit() {
    if (this.lendiEmployeeForm.valid) {

      const lendiEmployeeFormValues = this.lendiEmployeeForm.getRawValue();
      this.lendiEmployeeRecord = Object.assign(this.lendiEmployeeRecord, {...lendiEmployeeFormValues});

      this.spinner.show(this.spinnerName);

      const successFunc = (res) => {

        const showModalThenRedirect = () => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Employee profile successfuly saved.',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.back();
          });
        };

        const hasUpload = this.saveProfilePhoto();
        if (hasUpload) {
          hasUpload.then(showModalThenRedirect).finally(finallyFunc);
        } else {
          showModalThenRedirect();
        }
      };

      const errorFunc = (err) => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Failed to update document.',
          showConfirmButton: false,
          timer: 1500
        });
        console.error(err);
        finallyFunc();
      };

      const finallyFunc = () => {
        this.spinner.hide(this.spinnerName);
      };

      if (this.pageMode === PageMode.New) {
        this.$dbDummyApp
        .createAccount(this.lendiEmployeeRecord.accountDetails.email, this.lendiEmployeeRecord.accountDetails.password)
        .then(id => {
          this.lendiEmployeeRecord.id = id;
          this.$db.ref.doc(this.lendiEmployeeRecord.id)
          .set(this.lendiEmployeeRecord)
          .then(successFunc).catch(errorFunc);
        }).catch(errorFunc);
      } else {
        this.$db.ref.doc(this.lendiEmployeeRecord.id)
        .update(this.lendiEmployeeRecord)
        .then(successFunc).catch(errorFunc);
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please fill up all the required fields.',
        showConfirmButton: false,
        timer: 1500
      });
    }
  }

  saveProfilePhoto() {
    if (this.selectedFile !== null) {
      return this.$db.uploadProfilePhoto(this.lendiEmployeeRecord.id, new LendiPhoto(this.selectedFile));
    }

    return false;
  }

  back() {
    this.router.navigate(['employee-database']);
  }

  preview(files) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    const reader = new FileReader();
    this.selectedFile = files[0];
    reader.readAsDataURL(files[0]);
    reader.onload = (event) => {
      this.profilePhotoUrl = reader.result as any;
    };
  }

  onArchive() {
    const showModalThenRedirect = () => {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Employee profile successfuly archived.',
        showConfirmButton: false,
        timer: 1500
      }).then(() => {
        this.back();
      });
    };

    const errorFunc = (err) => {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Failed to update document.',
        showConfirmButton: false,
        timer: 1500
      });
      console.error(err);
      finallyFunc();
    };

    const finallyFunc = () => {
      this.spinner.hide(this.spinnerName);
    };

    Swal.fire({
      title: 'Archive Employee Profile',
      icon: 'warning',
      html: '<p>Are you sure you want to delete this record?</p>',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Yes',
      confirmButtonAriaLabel: 'Yes',
      cancelButtonText: 'No',
      cancelButtonAriaLabel: 'No',
    }).then(res => {
      if (res.value) {
        Promise.all([
          this.$dbDummyApp.deleteAccount(this.lendiEmployeeRecord.accountDetails.email, this.lendiEmployeeRecord.accountDetails.password),
          this.$db.ref.doc(this.lendiEmployeeRecord.id).update({isDeleted: true})
        ])
        .then(showModalThenRedirect)
        .catch(errorFunc)
        .finally(finallyFunc);
      }
    });
  }

}
