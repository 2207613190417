import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Spinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { environment } from 'projects/lendi-business/src/public-api';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  default = 'primary';

  constructor(private spinner: NgxSpinnerService) {}

  show(name = this.default) {
    if (name !== this.default) {
      this.spinner.show(name, environment.spinnerContainerConfig as Spinner);
    } else {
      this.spinner.show(name, environment.spinnerConfig as Spinner);
    }
  }

  hide(name = this.default) {
    this.spinner.hide(name);
  }
}
