import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { ItemVerificationModule } from './item-verification/item-verification.module';
import { PickupsModule } from './pickups/pickups.module';
import { ItemTrackerModule } from './item-tracker/item-tracker.module';
import { EmployeeModule } from './employee/employee.module';
import { UsersModule } from './users/users.module';
import { UserVerificationModule } from './user-verification/user-verification.module';
import { RiderModule } from './rider/rider.module';
import { SecurityDepositModule } from './security-deposit/security-deposit.module';
import { SharedModule } from './shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from 'projects/lendi-business/src/public-api';
import { AuthModule } from './auth/auth.module';
import { BorrowRequestsModule } from './borrow-requests/borrow-requests.module';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebaseConfig),
    BrowserAnimationsModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    BrowserModule,
    AppRoutingModule,
    DashboardModule,
    ItemVerificationModule,
    PickupsModule,
    ItemTrackerModule,
    EmployeeModule,
    UsersModule,
    UserVerificationModule,
    RiderModule,
    SecurityDepositModule,
    SharedModule,
    NgbModule,
    AuthModule,
    BorrowRequestsModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  exports: [
    BrowserAnimationsModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    NgbModule
  ]
})
export class AppModule { }
