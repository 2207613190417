import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ItemTrackerRoutingModule } from './item-tracker-routing.module';
import { ItemTrackerListComponent } from './item-tracker-list/item-tracker-list.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [ItemTrackerListComponent],
  imports: [
    CommonModule,
    ItemTrackerRoutingModule,
    SharedModule
  ]
})
export class ItemTrackerModule { }
