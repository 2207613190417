import { Component, OnInit, Input } from '@angular/core';
import { LendiProductRecord } from 'src/class/product.model';

@Component({
  selector: 'app-product-status',
  templateUrl: './product-status.component.html',
  styleUrls: ['./product-status.component.css']
})
export class ProductStatusComponent implements OnInit {

  @Input() productRecord: LendiProductRecord;
  statusLabel = '';
  statusClass = '';

  constructor() { }

  ngOnInit() {
    this.statusLabel = this.getStatusLabel();
    this.statusClass = this.getClass();
  }

  getClass(): string {
    if (this.productRecord) {
      if (this.productRecord.isBorrowed) {
        return 'item-borrowed';
      } else if (this.productRecord.isCancelled) {
        return 'text-danger';
      } else if (this.productRecord.isCompleted) {
        return 'item-available';
      } else if (false) {
          return 'item-to-ship';
      } else if (this.productRecord.isVerified) {
        return 'item-available';
      } else if (!this.productRecord.isVerified) {
        return 'item-borrowed';
      } else {
        return 'item-borrowed';
      }
    }
  }

  getStatusLabel() {
    if (this.productRecord) {
      if (this.productRecord.isBorrowed) {
        return 'Borrowed';
      } else if (this.productRecord.isCancelled) {
        return 'Cancelled';
      } else if (this.productRecord.isCompleted) {
        return 'Completed';
      } else if (false) { //Date
          return 'To Ship';
      } else if (this.productRecord.isVerified) {
        return 'Available';
      } else if (!this.productRecord.isVerified) {
        return 'For Verification';
      } else {
        return '';
      }
    }
  }

}
