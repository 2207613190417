import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

export const ForArchiveValue = 'forArchive';

@Component({
  selector: 'app-display-filter',
  templateUrl: './display-filter.component.html',
  styleUrls: ['./display-filter.component.css']
})
export class DisplayFilterComponent implements OnInit, OnDestroy {

  @Input() displayFilterValue: boolean | null | string;
  @Output() valueChanged = new EventEmitter<boolean | null | string>();

  displayFilterForm: FormGroup;
  displayFilterValueSubscription: Subscription;
  forArchiveValue = ForArchiveValue;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    if (this.displayFilterValue === undefined) {
      this.displayFilterValue = null;
    }

    this.displayFilterForm = this.fb.group({
      displayFilterSelect: [this.displayFilterValue]
    });

    this.displayFilterValueSubscription = this.displayFilterForm.get('displayFilterSelect').valueChanges.subscribe(val => {
      switch (val) {
        case 'true':
          this.displayFilterValue = true;
          break;
          case 'false':
            this.displayFilterValue = false;
            break;
            case this.forArchiveValue:
              this.displayFilterValue = this.forArchiveValue;
              break;
              case 'null':
              default:
                this.displayFilterValue = null;
                break;
      }

      this.valueChanged.emit(this.displayFilterValue);
    });
  }


  ngOnDestroy() {
    if (this.displayFilterValueSubscription) {
      this.displayFilterValueSubscription.unsubscribe();
    }
  }

}
