import { AbstractControl } from '@angular/forms';

const ERROR_MESSAGES = {
  email: {
    required: 'Please enter your email adress.',
    email: 'Please enter a valid email adress.'
  },
  password: {
    required: 'Please enter your password.',
    minlength: 'Please enter a minimum of six(6) characters.'
  },
  currentPassword: {
    required: 'Please enter your current password.',
    minlength: 'Please enter a minimum of six(6) characters.'
  },
  confirmPassword: {
    required: 'Please enter your confirmed password.'
  },
  passwordGroup: {
    match: 'Password doesn\'t match.'
  },
  newPasswordGroup: {
    match: 'Password doesn\'t match.'
  },
  firstname: {
    required: 'Please enter your first name.'
  },
  lastname: {
    required: 'Please enter your last name.'
  },
  birthdate: {
    required: 'Please enter your birth date.'
  },
  streetAddress: {
    required: 'Please enter your street address.',
    maxlength: 'Please enter a valid street address.'
  },
  barangay: {
    required: 'Please enter your barangay.',
    maxlength: 'Please enter a valid barangay.'
  },
  region: {
    required: 'Please select your region.',
    maxlength: 'Please enter a valid region.'
  },
  city: {
    required: 'Please select your city.',
    maxlength: 'Please enter a valid city.'
  },
  postalCode: {
    required: 'Please enter your postal code.',
    maxlength: 'Please enter a valid postal code.',
    pattern: 'Please enter a valid postal code.'
  },
  fromDate: {
    required: 'Please enter start date.'
  },
  fromTime: {
    required: 'Please select start time.'
  },
  toDate: {
    required: 'Please enter end date.'
  },
  toTime: {
    required: 'Please enter end time.'
  },
  brand: {
    required: 'Please enter vehicle brand.'
  },
  model: {
    required: 'Please enter vehicle model.'
  },
  plateNumber: {
    required: 'Please enter vehicle plate number.'
  }
};

export function setErrorMessage(c: AbstractControl, formControlName: string): string {
  if ((c.touched || c.dirty) && c.errors) {
    return Object.keys(c.errors)
      .map(key => ERROR_MESSAGES[formControlName][key])
      .join(' ');
  } else {
    return '';
  }
}
