import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerService } from 'src/app/services/spinner.service';
import { LendiProductRecord, LendiUserRecord } from 'projects/lendi-business/src/public-api';
import { ProductService } from 'src/app/services/product.service';
import Swal from 'sweetalert2';
import * as firebase from 'firebase';

@Component({
  selector: 'app-product-detail-modal',
  templateUrl: './product-detail-modal.component.html',
  styleUrls: ['./product-detail-modal.component.css']
})
export class ProductDetailModalComponent implements OnInit {

  @Input() productRecord: LendiProductRecord;
  @Input() userRecord: LendiUserRecord;

  spinnerName = 'ProductDetailModalComponent';

  constructor(public activeModal: NgbActiveModal, private spinner: SpinnerService, private $db: ProductService) { }

  ngOnInit() {}

  onConfirmVerification() {
    this.spinner.show(this.spinnerName);
    this.productRecord.isVerified = true;
    this.$db.ref.doc(this.productRecord.id).update(this.productRecord).then(res => {
      this.activeModal.close('onConfirmVerification');
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Product successfuly verified.',
        showConfirmButton: false,
        timer: 1500
      }).then(() => {
        this.spinner.hide(this.spinnerName);
        location.reload();
      });
    }).catch(error => {
      console.error(error);
    });
  }

  onArchive() {
    this.spinner.show(this.spinnerName);
    this.$db.ref.doc(this.productRecord.id).update({isDeleted: true}).then(res => {
      this.activeModal.close('onArchive');
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Product successfuly archived.',
        showConfirmButton: false,
        timer: 1500
      }).then(() => {
        this.spinner.hide(this.spinnerName);
        location.reload();
      });
    }).catch(error => {
      console.error(error);
    });
  }

  onDisapprove() {
    this.spinner.show(this.spinnerName);
    const sendProductFailedVerificationEmailAPI = firebase.functions().httpsCallable('sendProductFailedVerificationEmail');
    sendProductFailedVerificationEmailAPI({productRecord: this.productRecord}).then(res => {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Email notification sent to user.',
        showConfirmButton: false,
        timer: 1500
      }).then(() => {
        this.spinner.hide(this.spinnerName);
        location.reload();
      });
      this.activeModal.close('onDisapprove');
    }).catch(error => {
      console.error(error);
    });
  }

  closeModal() {
    this.activeModal.close('closed');
  }

}
