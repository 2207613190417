import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BorrowRequestsService } from './borrow-requests.service';
import { environment } from 'projects/lendi-business/src/public-api';
import { LendiRiderRecord } from 'projects/lendi-business/src/public-api';
import { ItemTrackerStatus, RiderTransactionRecord, RiderTransctionType } from 'src/class/borrow-request.model';
import * as firebase from 'firebase';
import { BorrowRequestRecord } from 'projects/lendi-business/src/public-api';

export const ammendableStatus = [
  ItemTrackerStatus.AssignedRiderFromLendertoBorrower,
  ItemTrackerStatus.RiderOnTheWayToLender,
  ItemTrackerStatus.FailedPickUpFromLender,
  ItemTrackerStatus.PickedUpFromLender,
  ItemTrackerStatus.FailedDeliveryToBorrower,
  ItemTrackerStatus.AssignedRiderFromBorrowerToLender,
  ItemTrackerStatus.RiderOnTheWayToBorrower,
  ItemTrackerStatus.FailedPickUpFromBorrower,
  ItemTrackerStatus.PickedUpFromBorrower,
  ItemTrackerStatus.FailedDeliveryToLender
];

@Injectable({
  providedIn: 'root'
})
export class RiderTransactionService {

  constructor(private $db: AngularFirestore) { }

  get ref() {
    return this.$db.collection(environment.collections.riderTransaction);
  }

  private createNewRiderTransaction(rider: LendiRiderRecord,
                                    borrowRequest: BorrowRequestRecord,
                                    status: ItemTrackerStatus,
                                    type: RiderTransctionType) {

    const id = this.ref.ref.doc().id;
    const createdAt = firebase.firestore.FieldValue.serverTimestamp();
    const snapshot = [
      Object.assign({}, {
        status,
        updatedAt: new Date()
      })
    ];
    const riderTransaction: RiderTransactionRecord = {
      id,
      createdAt,
      rider,
      borrowRequest,
      status,
      type,
      isActive: true,
      isDeleted: false,
      updatedAt: createdAt,
      snapshot
    };
    return this.ref.doc(id).set(Object.assign({}, riderTransaction)).catch(err => {
      console.error(err);
    });
  }

  getActiveTransactions() {
    return this.$db.collection(environment.collections.riderTransaction, ref => ref
      .where('isDeleted', '==', false)
      .where('isActive', '==', true)
      .orderBy('createdAt', 'desc')
    );
  }

  getTransactionsByRiderId(id: string) {
    return this.$db.collection(environment.collections.riderTransaction, ref => ref
      .where('isDeleted', '==', false)
      .where('rider.id', '==', id)
      .orderBy('createdAt', 'desc')
    ).get();
  }

  getLatestRiderTransaction(id: string) {
    return this.$db.collection(environment.collections.riderTransaction, ref => ref
      .where('isDeleted', '==', false)
      .where('borrowRequest.id', '==', id)
      .orderBy('createdAt', 'desc')
      .limit(1)
    ).get();
  }

  createRiderTransaction(rider: LendiRiderRecord,
                         borrowRequest: BorrowRequestRecord,
                         status: ItemTrackerStatus,
                         type: RiderTransctionType) {

    const createTransactionPromise = new Promise((resolve, reject) => {
      const updatedAt = firebase.firestore.FieldValue.serverTimestamp();

      try {
        this.createNewRiderTransaction(rider, borrowRequest, status, type).then((res) => {
          resolve(res);
        }).catch(err => {
          console.error(err);
          reject(err);
        });
      } catch (err) {
        console.error(err);
        reject(err);
      }

    });

    return createTransactionPromise;
  }

  updateRiderTransaction(riderTransaction: RiderTransactionRecord, status: ItemTrackerStatus) {

    const updateRiderTransactionPromise = new Promise((resolve, reject) => {

      const updatedAt = firebase.firestore.FieldValue.serverTimestamp();

      riderTransaction.status = status;
      riderTransaction.updatedAt = updatedAt;
      // riderTransaction.snapshot.push({
      //   status,
      //   updatedAt: new Date()
      // });

      try {
        this.ref.doc(riderTransaction.id).update(riderTransaction).then((res) => {
          resolve(res);
        }).catch(err => {
          console.error(err);
          reject(err);
        });
      } catch (err) {
        console.error(err);
        reject(err);
      }

    });

    return updateRiderTransactionPromise;
  }



}
