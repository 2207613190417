import { Pipe, PipeTransform } from '@angular/core';
import { RiderTransctionType, RiderTransactionRecord } from 'src/class/borrow-request.model';

@Pipe({
  name: 'riderTransactionTypeFilter'
})
export class RiderTransactionTypeFilterPipe implements PipeTransform {

  transform(value: RiderTransactionRecord[], type: RiderTransctionType): any {
    return value.filter(rt => rt.type === type);
  }

}
