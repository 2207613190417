import { Component, OnInit, Input } from '@angular/core';
import { LenderPayoutStatus } from 'src/class/borrow-request.model';
import { SpinnerService } from 'src/app/services/spinner.service';
import { BorrowRequestsService } from 'src/app/services/borrow-requests.service';
import { BorrowRequestRecord } from 'projects/lendi-business/src/public-api';

@Component({
  selector: 'app-user-lending-history',
  templateUrl: './user-lending-history.component.html',
  styleUrls: ['./user-lending-history.component.css']
})
export class UserLendingHistoryComponent implements OnInit {

  @Input() userId: string;
  spinnerName = 'UserLendingHistoryComponent';
  completedPayouts: BorrowRequestRecord[] = [];
  pendingPayouts: BorrowRequestRecord[] = [];

  constructor(private $dbBorrowRequests: BorrowRequestsService, private spinner: SpinnerService) { }

  ngOnInit() {
    this.spinner.show(this.spinnerName);
    Promise.all([
      this.$dbBorrowRequests.getByLenderPayoutStatusByLenderUserId(this.userId, LenderPayoutStatus.Completed)
      .toPromise().then(res => {
        this.completedPayouts = res.docs.map(d => d.data() as BorrowRequestRecord);
      }),
      this.$dbBorrowRequests.getByLenderPayoutStatusByLenderUserId(this.userId, LenderPayoutStatus.Pending)
      .toPromise().then(res => {
        this.pendingPayouts = res.docs.map(d => d.data() as BorrowRequestRecord);
      })
    ])
    .catch(err => {
      console.error(err);
    }).finally(() => {
      this.spinner.hide(this.spinnerName);
    });
  }

}
