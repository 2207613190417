import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';
import { setErrorMessage } from 'src/app/shared/validators/validation-messages';
import Swal from 'sweetalert2';
import * as firebase from 'firebase';
import { PageMode } from 'src/class/page-mode.enum';
import { LendiUserRecord, BorrowRequestRecord, BorrowRequestStatus } from 'projects/lendi-business/src/public-api';
import { LendiPhoto } from 'src/class/image.model';
import { PlaceholderAppReferenceService } from 'src/app/services/placeholder-app-reference.service';
import { compare, City, Province } from 'src/class/app-settings.model';
import { environment } from 'projects/lendi-business/src/public-api';
import { ProductService } from 'src/app/services/product.service';
import { BorrowRequestsService } from 'src/app/services/borrow-requests.service';

declare var $: any;

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit {

  spinnerName = 'UserDetailComponent';
  lendiUserRecord: LendiUserRecord;
  lendiUserForm: FormGroup = this.fb.group({
    profile: this.fb.group({
      email: ['', [Validators.required]],
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      middlename: [''],
      gender: ['Male', [Validators.required]],
      birthdate: [''],
      mobile: ['', [Validators.required]],
    }),
    profileAddress: this.fb.group({
      streetAddress: ['', [Validators.required, Validators.maxLength(255)]],
      barangay: ['', [Validators.required, Validators.maxLength(255)]],
      region: [null, [Validators.required, Validators.maxLength(255)]],
      city: [null, [Validators.required, Validators.maxLength(255)]],
      postalCode: ['', [Validators.required, Validators.maxLength(4)]]
    }),
    bankDetails: this.fb.group({
      accountType: ['', [Validators.required, Validators.maxLength(255)]],
      accountName: ['', [Validators.required, Validators.maxLength(255)]],
      accountNumber: ['', [Validators.required, Validators.maxLength(255)]],
    })
  });
  formErrors: { [key: string]: string };
  profilePhotoUrl: string;
  selectedFile: File = null;
  pageMode = PageMode.New;
  idFromRoute = '';
  provinces: Province[] =  environment.provinces;
  cities: City[] = environment.cities;

  constructor(
    private fb: FormBuilder,
    private spinner: SpinnerService,
    private router: Router,
    private activeRouter: ActivatedRoute,
    private $db: UsersService,
    private $dbDummyApp: PlaceholderAppReferenceService,
    private $product: ProductService,
    private $borrowRequest: BorrowRequestsService
  ) { }

  get city() {
    return this.lendiUserForm.get('profileAddress.city');
  }
  get region() {
    return this.lendiUserForm.get('profileAddress.region');
  }

  ngOnInit() {
    this.cities = this.cities.sort((a: City, b: City) => compare(a.description, b.description));
    this.setUpForm();
    this.setErrorSubscriptions();
    this.setDefaultLendiUserRecord();
  }

  setUpForm() {
    $('#birthdate').datepicker({
      uiLibrary: 'bootstrap',
      change: e => {
        this.lendiUserForm.get('profile.birthdate').setValue($(e.target).val());
      }
    });

    this.formErrors = {
      firstname: '',
      lastname: '',
      birthdate: '',
      mobile: '',
      gender: '',
      streetAddress: '',
      barangay: '',
      region: '',
      city: '',
      postalCode: '',
      email: '',
      accountType: '',
      accountName: '',
      accountNumber: '',
    };
  }

  setDefaultLendiUserRecord() {
    this.idFromRoute = this.activeRouter.snapshot.paramMap.get('id');
    this.lendiUserRecord = window.history.state.item;

    if (this.lendiUserRecord === undefined && this.idFromRoute === null) {
      this.lendiUserRecord = {
        id: this.$db.ref.ref.doc().id,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        isActive: true,
        isDeleted: false,
        profile: null,
        profileAddress: null,
        profilePhoto: null,
        verificationInfo: null,
        bankDetails: null
      };
      this.pageMode = PageMode.New;
      return;
    }

    this.pageMode = PageMode.Edit;
    if (this.lendiUserRecord === undefined && this.idFromRoute !== null) {
      this.spinner.show(this.spinnerName);
      this.$db.ref.doc(this.idFromRoute).get().toPromise().then(res => {
        this.lendiUserRecord = res.data() as LendiUserRecord;

        if (this.lendiUserRecord.profile.middlename === undefined) {
          this.lendiUserRecord.profile.middlename = '';
        }

        if (this.lendiUserRecord.bankDetails === undefined) {
          this.lendiUserRecord.bankDetails = {
            accountType: '',
            accountName: '',
            accountNumber: ''
          };
        }

        this.setFormValues(this.lendiUserRecord);
      }).catch(err => {
        console.error(err);
        this.back();
      }).finally(() => {
        this.spinner.hide(this.spinnerName);
      });
    } else {

      if (this.lendiUserRecord.profile.middlename === undefined) {
        this.lendiUserRecord.profile.middlename = '';
      }

      if (this.lendiUserRecord.bankDetails === undefined) {
        this.lendiUserRecord.bankDetails = {
          accountType: '',
          accountName: '',
          accountNumber: ''
        };
      }

      this.setFormValues(this.lendiUserRecord);
    }
  }

  setProfilePhoto() {
    if (this.lendiUserRecord.profilePhoto) {
      if (this.lendiUserRecord.profilePhoto.source) {
        this.profilePhotoUrl = this.lendiUserRecord.profilePhoto.source;
        return;
      }
    }

    this.profilePhotoUrl = undefined;
  }

  setFormValues(lendiUserRecord: LendiUserRecord) {
    this.lendiUserForm.get('profile').setValue(lendiUserRecord.profile);
    this.lendiUserForm.get('profileAddress').setValue(lendiUserRecord.profileAddress);
    this.lendiUserForm.get('bankDetails').setValue(lendiUserRecord.bankDetails);
    this.setProfilePhoto();
  }

  setErrorSubscriptions() {
    const profileControls = Object.keys((this.lendiUserForm.get('profile') as FormGroup).controls);
    const profileAddressControls = Object.keys((this.lendiUserForm.get('profileAddress') as FormGroup).controls);
    const bankDetailsControls = Object.keys((this.lendiUserForm.get('bankDetails') as FormGroup).controls);

    Object.keys(this.formErrors).map((key) => {
      const inProfile = profileControls.find(k => k === key) ? 'profile' : '';
      const inProfileAddress = inProfile === '' && profileAddressControls.find(k => k === key) ? 'profileAddress' : '';
      const inBankDetails = inProfile === '' && inProfileAddress === '' && bankDetailsControls.find(k => k === key) ? 'bankDetails' : '';
      const formGroupKey = inProfile + inProfileAddress + inBankDetails;
      this.lendiUserForm.get(formGroupKey + '.' + key).valueChanges.subscribe(value => {
        this.formErrors[key] = setErrorMessage(this.lendiUserForm.get(formGroupKey + '.' + key), key);
      });
    });
  }

  onSubmit() {
    if (this.lendiUserForm.valid) {

      const lendiUserFormValues = this.lendiUserForm.getRawValue();
      this.lendiUserRecord = Object.assign(this.lendiUserRecord, {...lendiUserFormValues});

      this.spinner.show(this.spinnerName);

      const successFunc = (res) => {

        const showModalThenRedirect = () => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'User profile successfuly saved.',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.back();
          });
        };

        const hasUpload = this.saveProfilePhoto();
        if (hasUpload) {
          hasUpload.then(showModalThenRedirect).finally(finallyFunc);
        } else {
          showModalThenRedirect();
        }
      };

      const errorFunc = (err) => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Failed to update document.',
          showConfirmButton: false,
          timer: 1500
        });
        console.error(err);
        finallyFunc();
      };

      const finallyFunc = () => {
        this.spinner.hide(this.spinnerName);
      };

      if (this.pageMode === PageMode.New) {
        this.$db.ref.doc(this.lendiUserRecord.id)
        .set(this.lendiUserRecord)
        .then(successFunc)
        .catch(errorFunc);
      } else {
        this.$db.ref.doc(this.lendiUserRecord.id)
        .update(this.lendiUserRecord)
        .then(successFunc)
        .catch(errorFunc);
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please fill up all the required fields.',
        showConfirmButton: false,
        timer: 1500
      });
    }
  }

  saveProfilePhoto() {
    if (this.selectedFile !== null) {
      return this.$db.uploadProfilePhoto(this.lendiUserRecord.id, new LendiPhoto(this.selectedFile));
    }

    return false;
  }

  back() {
    this.router.navigate(['user-database']);
  }

  preview(files) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    const reader = new FileReader();
    this.selectedFile = files[0];
    reader.readAsDataURL(files[0]);
    reader.onload = (event) => {
      this.profilePhotoUrl = reader.result as any;
    };
  }

  onArchive() {
    const showModalThenRedirect = () => {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'User profile successfuly archived.',
        showConfirmButton: false,
        timer: 1500
      }).then(() => {
        this.back();
      });
    };

    const errorFunc = (err) => {
      console.error(err);
      finallyFunc();
    };

    const finallyFunc = () => {
      this.spinner.hide(this.spinnerName);
    };

    Swal.fire({
      title: 'Archive User Profile',
      icon: 'warning',
      html: '<p>Are you sure you want to delete this record?</p>',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Yes',
      confirmButtonAriaLabel: 'Yes',
      cancelButtonText: 'No',
      cancelButtonAriaLabel: 'No',
    }).then(res => {
      if (res.value) {
        this.spinner.show(this.spinnerName);

        Promise.all([
          this.archiveProducts(),
          this.cancelPendingBorrowRequest(),
          this.cancelPendingLendingRequest()
        ]).then(dataRes => {
          this.lendiUserRecord.profilePhoto.source = 'https://firebasestorage.googleapis.com/v0/b/lendi-4edd9.appspot.com/o/app-settings%2Fplaceholder%2Fverify-placeholder.png?alt=media&token=fec5a3e2-9a2b-4d6a-a130-d4c15418d76c';
          this.lendiUserRecord.bankDetails.accountName = null;
          this.lendiUserRecord.bankDetails.accountType = null;
          this.lendiUserRecord.bankDetails.accountNumber = null;
          this.lendiUserRecord.profile.birthdate = null;
          this.lendiUserRecord.profile.gender = null;
          this.lendiUserRecord.profile.mobile = null;
          this.lendiUserRecord.profile.email = null;
          this.lendiUserRecord.profile.middlename = null;
          this.lendiUserRecord.profile.firstname = [this.lendiUserRecord.profile.firstname, '(Deleted)'].join(' ');
          this.lendiUserRecord.profile.lastname = [this.lendiUserRecord.profile.lastname, '(Deleted)'].join(' ');
          this.lendiUserRecord.profileAddress.barangay = null;
          this.lendiUserRecord.profileAddress.city = null;
          this.lendiUserRecord.profileAddress.postalCode = null;
          this.lendiUserRecord.profileAddress.region = null;
          this.lendiUserRecord.profileAddress.streetAddress = null;
          this.lendiUserRecord.verificationInfo.verificationPhoto = 'https://firebasestorage.googleapis.com/v0/b/lendi-4edd9.appspot.com/o/app-settings%2Fplaceholder%2Fverify-placeholder.png?alt=media&token=fec5a3e2-9a2b-4d6a-a130-d4c15418d76c';
          this.lendiUserRecord.isDeleted = true;
          this.lendiUserRecord.deleteDate = firebase.firestore.FieldValue.serverTimestamp();

          const archiveLendiUserAccountAPI = firebase.functions().httpsCallable('archiveLendiUserAccount');

          archiveLendiUserAccountAPI({uid: this.lendiUserRecord.id})
          .then(res => {
            this.$db.ref.doc(this.lendiUserRecord.id).update(this.lendiUserRecord)
            .then(showModalThenRedirect)
            .catch(errorFunc)
            .finally(finallyFunc);
          }).catch(errorFunc);

        }).catch(errorFunc);
      }
    });
  }

  archiveProducts() {
    const archiveProductsPromise = new Promise((resolve, reject) => {
      this.$product.getRefByUid(this.lendiUserRecord.id).get().toPromise().then(res => {

        if (res.docs.length === 0 ) { resolve(true); return; }

        Promise.all(res.docs.map(d => d.ref.update({isDeleted: true}))).then(prodRes => {
          resolve(true);
        }).catch(err => {
          console.error(err);
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: `Oops! Something went wrong while deactivating user's products.`,
            showConfirmButton: false,
            timer: 2000
          });
          reject(false);
        });
      }).catch(err => {
        console.error(err);
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: `Oops! Something went wrong while deactivating user's products.`,
          showConfirmButton: false,
          timer: 2000
        });
        reject(false);
      });
    });

    return archiveProductsPromise;
  }

  cancelPendingBorrowRequest() {
    const cancelPendingBorrowRequestPromise = new Promise((resolve, reject) => {
      this.$borrowRequest.getRefBorrowRequestByUid(this.lendiUserRecord.id).get().toPromise().then(res => {

        if (res.docs.length === 0 ) { resolve(true); return; }

        const pendingPayments = res.docs.filter(d => {
          const val = d.data() as BorrowRequestRecord;
          if (!(val.isPaid)) {
            return true;
          }
        });

        if (pendingPayments.length === 0 ) { resolve(true); return; }

        Promise.all(pendingPayments.map(d =>
          d.ref.update({ status: BorrowRequestStatus.Cancelled })
        )).then(prodRes => {
          resolve(true);
        }).catch(err => {
          console.error(err);
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: `Oops! Something went wrong while cancelling user's pending borrow requests.`,
            showConfirmButton: false,
            timer: 2000
          });
          reject(false);
        });
      }).catch(err => {
        console.error(err);
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: `Oops! Something went wrong while cancelling user's pending borrow requests.`,
          showConfirmButton: false,
          timer: 2000
        });
        reject(false);
      });

    });

    return cancelPendingBorrowRequestPromise;
  }

  cancelPendingLendingRequest() {
    const cancelPendingBorrowRequestPromise = new Promise((resolve, reject) => {
      this.$borrowRequest.getRefBorrowRequestByUid(this.lendiUserRecord.id).get().toPromise().then(res => {

        if (res.docs.length === 0 ) { resolve(true); return; }

        const pendingPayments = res.docs.filter(d => {
          const val = d.data() as BorrowRequestRecord;
          if (!(val.isPaid)) {
            return true;
          }
        });

        if (pendingPayments.length === 0 ) { resolve(true); return; }

        Promise.all(pendingPayments.map(d =>
          d.ref.update({ status: BorrowRequestStatus.Cancelled })
        )).then(prodRes => {
          resolve(true);
        }).catch(err => {
          console.error(err);
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: `Oops! Something went wrong while cancelling user's pending lending requests.`,
            showConfirmButton: false,
            timer: 2000
          });
          reject(false);
        });
      }).catch(err => {
        console.error(err);
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: `Oops! Something went wrong while cancelling user's pending lending requests.`,
          showConfirmButton: false,
          timer: 2000
        });
        reject(false);
      });

    });

    return cancelPendingBorrowRequestPromise;
  }

}
