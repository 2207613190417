import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BorrowRequestsRoutingModule } from './borrow-requests-routing.module';
import { BorrowRequestsListComponent } from './borrow-requests-list/borrow-requests-list.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [BorrowRequestsListComponent],
  imports: [
    CommonModule,
    BorrowRequestsRoutingModule,
    SharedModule
  ]
})
export class BorrowRequestsModule { }
