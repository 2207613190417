import { Component, OnInit, Input } from '@angular/core';
import { LendiUserRecord } from 'src/class/user.model';
import { UsersService } from 'src/app/services/users.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-verification-info',
  templateUrl: './user-verification-info.component.html',
  styleUrls: ['./user-verification-info.component.css']
})
export class UserVerificationInfoComponent implements OnInit {

  spinnerName = 'UserDetailComponent';

  @Input() userRecord: LendiUserRecord;

  constructor(private $userService: UsersService, private spinner: SpinnerService) { }

  ngOnInit() {
  }

  verifyUser() {
    Swal.fire({
      title: 'Verify User Profile',
      icon: 'warning',
      html: '<p>Are you sure you want to verify this user?</p>',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Yes',
      confirmButtonAriaLabel: 'Yes',
      cancelButtonText: 'No',
      cancelButtonAriaLabel: 'No',
    }).then(res => {
      if (res.value) {
        this.spinner.show(this.spinnerName);
        this.$userService.ref.doc(this.userRecord.id).update(Object.assign(this.userRecord.verificationInfo, {isVerified: true}))
        .then(response => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'User profile successfuly verified.',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            location.reload();
          });
        })
        .catch(err => {
          console.error(err);
        }).finally(() => {
          this.spinner.hide(this.spinnerName);
        });
      }
    });
  }

}
