import { Pipe, PipeTransform } from '@angular/core';
import { LendiUserProfileAddress } from 'src/class/user.model';
import { ProductPickUpLocation } from 'src/class/product.model';

@Pipe({
  name: 'displayAddress'
})
export class DisplayAddressPipe implements PipeTransform {

  transform(value: LendiUserProfileAddress | ProductPickUpLocation, ...args: any[]): any {
    return `${value.streetAddress || ''}, ${value.barangay || ''}, ${value.city || ''},  ${value['region'] || value['province'] || ''}  ${value.postalCode || ''}`;
  }

}
