import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment, ItemTrackerStatus, LendiUserRecord, VerificationStatus, BorrowRequestStatus } from 'projects/lendi-business/src/public-api';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  lenderToBorrowerCount = 0;
  borrowerToLenderCount = 0;
  pendingUserVerificationCount = 0;
  pendingProductVerificationCount = 0;
  pendingAccountDeletionRequestCount = 0;

  constructor(private $db: AngularFirestore) {}

  getDashboardCounts() {
    const oldDate = new Date();
    oldDate.setFullYear(2019);
    oldDate.setMonth(0);
    oldDate.setDate(1);

    return Promise.all([
      this.$db.collection(environment.collections.borrowRequests,
        ref => ref
        .where('isDeleted', '==', false)
        .where('isPaid', '==', true)
        .where('verificationStatus', '==', VerificationStatus.Approved)
        .where('status', 'in', [BorrowRequestStatus.Pending, BorrowRequestStatus.Current])
        .where('itemTrackerStatus', '<', ItemTrackerStatus.AssignedRiderFromLendertoBorrower)
        .orderBy('itemTrackerStatus', 'asc')
        .orderBy('createdAt', 'desc')
      ).get().toPromise().then(res => {
        this.lenderToBorrowerCount = res.size;
      }),
      this.$db.collection(environment.collections.borrowRequests,
        ref => ref
        .where('isDeleted', '==', false)
        .where('isPaid', '==', true)
        .where('verificationStatus', '==', VerificationStatus.Approved)
        .where('status', 'in', [BorrowRequestStatus.Pending, BorrowRequestStatus.Current])
        .where('itemTrackerStatus', '==', ItemTrackerStatus.DeliverySuccessfulToBorrower)
        .orderBy('createdAt', 'desc')
      ).get().toPromise().then(res => {
        this.borrowerToLenderCount = res.size;
      }),
      this.$db.collection(environment.collections.products,
        ref => ref
        .where('isDeleted', '==', false)
        .where('isVerified', '==', false)
        .where('slot.isExpired', '==', false)
      ).get().toPromise().then(res => {
        this.pendingProductVerificationCount = res.size;
      }),
      this.$db.collection(environment.collections.userProfiles,
        ref => ref
        .where('isDeleted', '==', false)
        .where('verificationInfo.isVerified', '==', false)
        .orderBy('createdAt', 'desc')
      ).get().toPromise().then(res => {
        const users = res.docs.map(u => u.data() as LendiUserRecord).filter(d => {
          return (d.verificationInfo.verificationPhoto !== null &&
          d.profileAddress.barangay !== null &&
          d.profileAddress.city !== null &&
          d.profileAddress.postalCode !== null &&
          d.profileAddress.region !== null &&
          d.profilePhoto.source !== null);
        });
        this.pendingUserVerificationCount = users.length;
      }),
      this.$db.collection(environment.collections.userProfiles,
        ref => ref
        .where('isDeleted', '==', false)
        .where('deleteProfileRequestDate', '>', oldDate)
        .orderBy('deleteProfileRequestDate')
      ).get().toPromise().then(res => {
        const users = res.docs.map(u => u.data() as LendiUserRecord).filter(d => {
          return d.deleteDate === undefined && !d.isDeleted;
        });
        this.pendingAccountDeletionRequestCount = users.length;
      })
    ]).then(res => {
      return res;
    }).catch(err => {
      console.error(err);
      return err;
    });
  }
}
