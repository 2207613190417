import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsersRoutingModule } from './users-routing.module';
import { UserListComponent } from './user-list/user-list.component';
import { SharedModule } from '../shared/shared.module';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UserVerificationInfoComponent } from './user-verification-info/user-verification-info.component';
import { UserRatingsInfoComponent } from './user-ratings-info/user-ratings-info.component';
import { UserProductsComponent } from './user-products/user-products.component';
import { UserLendingHistoryComponent } from './user-lending-history/user-lending-history.component';
import { UserBorrowingHistoryComponent } from './user-borrowing-history/user-borrowing-history.component';


@NgModule({
  declarations: [UserListComponent, UserDetailComponent, UserVerificationInfoComponent, UserRatingsInfoComponent, UserProductsComponent, UserLendingHistoryComponent, UserBorrowingHistoryComponent],
  imports: [
    CommonModule,
    UsersRoutingModule,
    SharedModule
  ]
})
export class UsersModule { }
