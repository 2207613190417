import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';
import { first, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SpinnerService } from 'src/app/services/spinner.service';
import { LendiUserRecord } from 'src/class/user.model';
import { UserDetailModalComponent } from 'src/app/shared/user-detail-modal/user-detail-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-verification-list',
  templateUrl: './user-verification-list.component.html',
  styleUrls: ['./user-verification-list.component.css']
})
export class UserVerificationListComponent implements OnInit, OnDestroy {

  spinnerName = 'UserVerificationListComponent';
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  userItems: LendiUserRecord[] = [];

  constructor(private $db: UsersService, private spinner: SpinnerService, private modalService: NgbModal) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };

    this.spinner.show(this.spinnerName);
    this.$db.getUnverifiedUsers().valueChanges().pipe(first()).subscribe(users => {
      this.userItems = users as any;
      this.userItems = this.userItems.filter(d => {
        return (d.verificationInfo.verificationPhoto !== null &&
        d.profileAddress.barangay !== null &&
        d.profileAddress.city !== null &&
        d.profileAddress.postalCode !== null &&
        d.profileAddress.region !== null &&
        d.profilePhoto.source !== null);
      });
      this.dtTrigger.next(this.userItems);
      this.spinner.hide(this.spinnerName);
    });
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  openUserDetailModal(user: LendiUserRecord) {
    const modalRef = this.modalService.open(UserDetailModalComponent, { size: 'lg' });
    modalRef.componentInstance.user = user;
  }

}
