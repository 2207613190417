import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerService } from 'src/app/services/spinner.service';
import Swal from 'sweetalert2';
import { ItemTrackerStatus, RiderTransctionType } from 'src/class/borrow-request.model';
import { RidersService } from 'src/app/services/riders.service';
import { LendiRiderRecord } from 'projects/lendi-business/src/public-api';
import { ItemTrackerService } from 'src/app/services/item-tracker.service';
import { RiderTransactionService } from 'src/app/services/rider-transaction.service';
import { LendiUserRecord, BorrowRequestRecord } from 'projects/lendi-business/src/public-api';

@Component({
  selector: 'app-assign-rider-modal',
  templateUrl: './assign-rider-modal.component.html',
  styleUrls: ['./assign-rider-modal.component.css']
})
export class AssignRiderModalComponent implements OnInit {

  @Input() borrowRequest: BorrowRequestRecord;
  @Input() itemTrackerStatus: ItemTrackerStatus;
  @Input() borrowerProfile: LendiUserRecord;
  @Input() lenderProfile: LendiUserRecord;
  @Input() type: RiderTransctionType;

  riderRecords: LendiRiderRecord[] = [];
  selectedRiderId: string = null;

  spinnerName = 'AssignRiderModalComponent';

  constructor(
    public activeModal: NgbActiveModal,
    private spinner: SpinnerService,
    private $dbTracker: ItemTrackerService,
    private $dbRiders: RidersService,
    private $dbRiderTransaction: RiderTransactionService) { }

  ngOnInit() {
    this.spinner.show(this.spinnerName);
    this.selectedRiderId = this.setSelectedRiderId();

    this.$dbRiders.ref.get().toPromise().then(res => {
      this.riderRecords = res.docs.map(d => d.data() as LendiRiderRecord);
    }).catch(err => {
      console.error(err);
    }).finally(() => {
      this.spinner.hide(this.spinnerName);
    });
  }

  setSelectedRiderId() {
    if (this.borrowRequest.itemTracker) {
      if (this.borrowRequest.itemTracker.riderProfile) {
        return this.borrowRequest.itemTracker.riderProfile.id;
      }
    }

    return null;
  }

  onAssign() {
    const selectedRider = this.riderRecords.find(r => r.id === this.selectedRiderId);

    this.spinner.show(this.spinnerName);

    if (selectedRider !== undefined && selectedRider !== null) {
      this.$dbRiderTransaction.createRiderTransaction(selectedRider, this.borrowRequest, this.itemTrackerStatus, this.type).then(() => {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Rider successfuly assigned.',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          location.reload();
          this.activeModal.close('onAssign');
        });
      }).catch(err => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Something when wrong. Please contact support.',
          showConfirmButton: false,
          timer: 1500
        });
      }).finally(() => {
        this.spinner.hide(this.spinnerName);
      });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Please input all the required fields.',
        showConfirmButton: false,
        timer: 1500
      });
    }
  }

  onClose() {
    this.closeModal();
  }

  closeModal() {
    this.activeModal.close('closed');
  }

}
