import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BorrowRequestsListComponent } from './borrow-requests-list/borrow-requests-list.component';

const routes: Routes = [
  { path: 'overview', component: BorrowRequestsListComponent },
  { path: '', pathMatch: 'full', redirectTo: 'overview' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BorrowRequestsRoutingModule { }
