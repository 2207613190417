import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../services/dashboard.service';
import { SpinnerService } from '../services/spinner.service';

declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  spinnerName = 'DashboardComponent';
  lenderToBorrowerCount = 0;
  borrowerToLenderCount = 0;
  pendingUserVerificationCount = 0;
  pendingProductVerificationCount = 0;
  pendingAccountDeletionRequestCount = 0;

  constructor(private $db: DashboardService, private spinner: SpinnerService) { }

  ngOnInit() {
    this.spinner.show(this.spinnerName);
    this.$db.getDashboardCounts().then(res => {
      // success
    }).catch(err => {
      console.error(err);
    }).finally(() => {
      this.lenderToBorrowerCount = this.$db.lenderToBorrowerCount;
      this.borrowerToLenderCount = this.$db.borrowerToLenderCount;
      this.pendingUserVerificationCount = this.$db.pendingProductVerificationCount;
      this.pendingProductVerificationCount = this.$db.pendingUserVerificationCount;
      this.pendingAccountDeletionRequestCount = this.$db.pendingAccountDeletionRequestCount;
      this.spinner.hide(this.spinnerName);
    });
  }

}
