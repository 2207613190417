import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RiderRoutingModule } from './rider-routing.module';
import { RiderListComponent } from './rider-list/rider-list.component';
import { SharedModule } from '../shared/shared.module';
import { RiderHomeComponent } from './rider-home/rider-home.component';
import { RiderDetailComponent } from './rider-detail/rider-detail.component';
import { PickupDeliveryHistoryComponent } from './pickup-delivery-history/pickup-delivery-history.component';
import { PickupDeliveryCardComponent } from './pickup-delivery-card/pickup-delivery-card.component';


@NgModule({
  declarations: [RiderListComponent, RiderHomeComponent, RiderDetailComponent, PickupDeliveryHistoryComponent, PickupDeliveryCardComponent],
  imports: [
    CommonModule,
    RiderRoutingModule,
    SharedModule
  ]
})
export class RiderModule { }
