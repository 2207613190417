import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SpinnerService } from 'src/app/services/spinner.service';
import { LendiUserRecord } from 'src/class/user.model';
import { UserDetailModalComponent } from 'src/app/shared/user-detail-modal/user-detail-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-security-deposit-list',
  templateUrl: './security-deposit-list.component.html',
  styleUrls: ['./security-deposit-list.component.css']
})
export class SecurityDepositListComponent implements OnInit, OnDestroy {

  spinnerName = 'SecurityDepositListComponent';
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  userItems: LendiUserRecord[] = [];

  constructor(private $db: UsersService, private spinner: SpinnerService, private modalService: NgbModal) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };

    this.spinner.show(this.spinnerName);
    this.$db.ref.valueChanges().pipe(first()).subscribe(users => {
      this.userItems = users as any;
      this.dtTrigger.next();
      this.spinner.hide(this.spinnerName);
    });
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  openUserDetailModal(user: LendiUserRecord) {
    const modalRef = this.modalService.open(UserDetailModalComponent, { size: 'lg' });
    modalRef.componentInstance.user = user;
  }

}
