import { Component, OnInit, Input } from '@angular/core';
import { RiderTransactionService } from 'src/app/services/rider-transaction.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { UsersService } from 'src/app/services/users.service';
import { RiderTransactionRecord, LendiRiderRecord, LendiUserRecord, unique } from 'projects/lendi-business/src/public-api';

@Component({
  selector: 'app-pickup-delivery-history',
  templateUrl: './pickup-delivery-history.component.html',
  styleUrls: ['./pickup-delivery-history.component.css']
})
export class PickupDeliveryHistoryComponent implements OnInit {

  @Input() rider: LendiRiderRecord;

  spinnerName = 'PickupDeliveryHistoryComponent';
  riderTransactions: RiderTransactionRecord[] = [];
  userProfiles: LendiUserRecord[] = [];

  constructor(private spinner: SpinnerService, private $db: RiderTransactionService, private $dbUsers: UsersService) { }

  ngOnInit() {

    if (this.rider !== undefined && this.rider !== undefined) {
      this.spinner.show(this.spinnerName);
      this.$db.getTransactionsByRiderId(this.rider.id).toPromise().then((res) => {
        this.riderTransactions = res.docs.map(d => d.data() as RiderTransactionRecord);

        const borrowerUids = this.riderTransactions.map(rt => rt.borrowRequest.uid);
        const lenderUids = this.riderTransactions.map(rt => rt.borrowRequest.product.uid);
        const uids = unique([].concat(borrowerUids, lenderUids));

        if (uids.length) {
          this.$dbUsers.getUserProfiles(uids).toPromise().then(users => {
            this.userProfiles = users.docs.map(d => d.data() as LendiUserRecord);
          }).finally(() => {
            this.spinner.hide(this.spinnerName);
          });
        } else {
          this.spinner.hide(this.spinnerName);
        }

      }).catch(err => {
        console.error(err);
      });
    }

  }

  getUserProfile(id: string) {
    return this.userProfiles.find(u => u.id === id);
  }

}
