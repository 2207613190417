export function unique(context: any[]) {
  const a = context.concat();
  for (let i = 0; i < a.length; ++i) {
      for (let j = i + 1; j < a.length; ++j) {
          if (a[i] === a[j]) {
              a.splice(j--, 1);
          }
      }
  }

  return a;
}
