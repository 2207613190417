import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ItemVerificationListComponent } from './item-verification-list/item-verification-list.component';

const routes: Routes = [
  { path: 'overview', component: ItemVerificationListComponent },
  { path: '', pathMatch: 'full', redirectTo: 'overview' }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ItemVerificationRoutingModule { }
