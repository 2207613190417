import { Component, OnInit, Input } from '@angular/core';
import { BorrowRequestsService } from 'src/app/services/borrow-requests.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { BorrowRequestStatus } from 'src/class/borrow-request.model';
import { BorrowRequestRecord } from 'projects/lendi-business/src/public-api';

@Component({
  selector: 'app-user-borrowing-history',
  templateUrl: './user-borrowing-history.component.html',
  styleUrls: ['./user-borrowing-history.component.css']
})
export class UserBorrowingHistoryComponent implements OnInit {

  @Input() userId: string;
  spinnerName = 'UserLendingHistoryComponent';

  currentRecords: BorrowRequestRecord[] = [];
  upcomingRecords: BorrowRequestRecord[] = [];
  completedRecords: BorrowRequestRecord[] = [];
  canceledRecords: BorrowRequestRecord[] = [];

  constructor(private $dbBorrowRequests: BorrowRequestsService, private spinner: SpinnerService) { }

  ngOnInit() {
    this.spinner.show(this.spinnerName);
    Promise.all([
      this.$dbBorrowRequests.getByBorrowRequestStatusByBorrowerUserId(this.userId, BorrowRequestStatus.Completed)
      .toPromise().then(res => {
        this.completedRecords = res.docs.map(d => d.data() as BorrowRequestRecord);
      }),
      this.$dbBorrowRequests.getByBorrowRequestStatusByBorrowerUserId(this.userId, BorrowRequestStatus.Pending)
      .toPromise().then(res => {
        this.upcomingRecords = res.docs.map(d => d.data() as BorrowRequestRecord);
      }),
      this.$dbBorrowRequests.getByBorrowRequestStatusByBorrowerUserId(this.userId, BorrowRequestStatus.Current)
      .toPromise().then(res => {
        this.currentRecords = res.docs.map(d => d.data() as BorrowRequestRecord);
      }),
      this.$dbBorrowRequests.getByBorrowRequestStatusByBorrowerUserId(this.userId, BorrowRequestStatus.Cancelled)
      .toPromise().then(res => {
        this.canceledRecords = res.docs.map(d => d.data() as BorrowRequestRecord);
      })
    ])
    .catch(err => {
      console.error(err);
    }).finally(() => {
      this.spinner.hide(this.spinnerName);
    });
  }

}
