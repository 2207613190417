import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserVerificationListComponent } from './user-verification-list/user-verification-list.component';


const routes: Routes = [
  { path: 'overview', component: UserVerificationListComponent },
  { path: '', pathMatch: 'full', redirectTo: 'overview' }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserVerificationRoutingModule { }
