import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { environment } from 'projects/lendi-business/src/public-api';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private afStore: AngularFirestore, private afStorage: AngularFireStorage) { }

  get ref() {
    return this.afStore.collection(environment.collections.products);
  }

  getRefByUid(uid: string) {
    return this.afStore.collection(environment.collections.products, ref => ref
      .where('uid', '==', uid)
    );
  }

  getUnverifiedProducts() {
    return this.afStore
      .collection(environment.collections.products, ref =>
        ref
          .where('isDeleted', '==', false)
          .where('isVerified', '==', false)
          .where('slot.isExpired', '==', false)
          .orderBy('createdAt', 'desc')
      );
  }

  getProductsByUserId(uid: string, itemsPerPage: number) {
    return this.afStore
      .collection(environment.collections.products, ref =>
        ref
          .limit(itemsPerPage)
          .where('uid', '==', uid)
          .where('isDeleted', '==', false)
          .orderBy('createdAt', 'desc')
      ).get();
  }

  getProductsForVerificationByUserId(
    uid: string,
    itemsPerPage: number
  ) {
    return this.afStore
      .collection(environment.collections.products, ref =>
        ref
          .limit(itemsPerPage)
          .where('uid', '==', uid)
          .where('isDeleted', '==', false)
          .where('isVerified', '==', false)
          .orderBy('createdAt', 'desc')
      ).get();
  }

  getProductsAvialableByUserId(
    uid: string,
    itemsPerPage: number
  ) {
    return this.afStore
      .collection(environment.collections.products, ref =>
        ref
          .limit(itemsPerPage)
          .where('uid', '==', uid)
          .where('isDeleted', '==', false)
          .where('isVerified', '==', true)
          .where('isCompleted', '==', false)
          .where('isCancelled', '==', false)
          .where('isBorrowed', '==', false)
          .orderBy('createdAt', 'desc')
      ).get();
  }

  getProductsCompletedByUserId(
    uid: string,
    itemsPerPage: number
  ) {
    return this.afStore
      .collection(environment.collections.products, ref =>
        ref
          .limit(itemsPerPage)
          .where('uid', '==', uid)
          .where('isDeleted', '==', false)
          .where('isCompleted', '==', true)
          .orderBy('createdAt', 'desc')
      ).get();
  }

  getProductsCancelledByUserId(
    uid: string,
    itemsPerPage: number
  ) {
    return this.afStore
      .collection(environment.collections.products, ref =>
        ref
          .limit(itemsPerPage)
          .where('uid', '==', uid)
          .where('isDeleted', '==', false)
          .where('isBorrowed', '==', true)
          .orderBy('createdAt', 'desc')
      ).get();
  }

  getProductsBorrowedByUserId(
    uid: string,
    itemsPerPage: number
  ) {
    return this.afStore
      .collection(environment.collections.products, ref =>
        ref
          .limit(itemsPerPage)
          .where('uid', '==', uid)
          .where('isDeleted', '==', false)
          .where('isCancelled', '==', true)
          .orderBy('createdAt', 'desc')
      ).get();
  }
}
