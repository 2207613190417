import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'projects/lendi-business/src/public-api';
import { ItemTrackerStatus, ItemTracker } from 'src/class/borrow-request.model';
import * as firebase from 'firebase';
import { BorrowRequestsService } from './borrow-requests.service';
import { LendiRiderRecord } from 'projects/lendi-business/src/public-api';

@Injectable({
  providedIn: 'root'
})
export class ItemTrackerService {

  constructor(private $db: AngularFirestore, private $dbBorrowRequest: BorrowRequestsService) { }

  getRef(id: string) {
    return this.$db.collection(`${environment.collections.itemStatusTracker}-${id}`);
  }

  updateTracker(id: string, status: ItemTrackerStatus, riderProfile?: LendiRiderRecord) {
    const ref = this.getRef(id);
    const newStatus = {
      id: ref.ref.doc().id,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      isActive: true,
      isDeleted: false,
      itemTrackerStatus: status
    } as ItemTracker;

    if ((
        status === ItemTrackerStatus.AssignedRiderFromBorrowerToLender ||
        status === ItemTrackerStatus.AssignedRiderFromLendertoBorrower
      ) && riderProfile !== undefined) {
      newStatus.riderProfile = riderProfile;
    }

    const updatePromise  = new Promise((resolve, reject) => {

      this.overwriteStatus(id).catch(err => {
        console.error(err);
        reject(err);
      }).finally(() => {

        Promise.all([
          this.$dbBorrowRequest.ref.doc(id).update({itemTracker: newStatus, itemTrackerStatus: status}),
          this.getRef(id).doc(newStatus.id).set(newStatus)
        ]).catch(err => {
          console.error(err);
          reject(err);
        }).finally(() => {
          resolve(newStatus);
        });

      });

    });

    return updatePromise;
  }

  private overwriteStatus(id: string) {
    const overwritePromise = new Promise((resolve, reject) => {

      let trackerStatus = [];

      this.$db.collection(`${environment.collections.itemStatusTracker}-${id}`,
        ref => ref
        .where('isActive', '==', true)
        .where('isDeleted', '==', false)
      ).get().toPromise().then(res => {
        trackerStatus = res.docs;
      }).catch(err => {
        console.error(err);
        reject(false);
      }).finally(() => {
        Promise.all(trackerStatus.map(d => d.ref.update({isActive: false}))).then(res => {
          resolve(true);
        }).catch(err => {
          console.error(err);
          reject(false);
        });
      });
    });

    return overwritePromise;
  }

}
