import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerService } from 'src/app/services/spinner.service';
import { LendiUserRecord } from 'projects/lendi-business/src/public-api';
import { UsersService } from 'src/app/services/users.service';
import Swal from 'sweetalert2';
import * as firebase from 'firebase';

@Component({
  selector: 'app-user-detail-modal',
  templateUrl: './user-detail-modal.component.html',
  styleUrls: ['./user-detail-modal.component.css']
})
export class UserDetailModalComponent implements OnInit {

  @Input() user: LendiUserRecord;

  spinnerName = 'UserDetailModalComponent';
  profilePhoto = '';

  constructor(public activeModal: NgbActiveModal, private spinner: SpinnerService, private $db: UsersService) { }

  ngOnInit() {
    if (this.user.profilePhoto.source === 'assets/images/verify-placeholder.png') {
      this.profilePhoto = 'https://firebasestorage.googleapis.com/v0/b/lendi-4edd9.appspot.com/o/app-settings%2Fplaceholder%2Fverify-placeholder.png?alt=media&token=fec5a3e2-9a2b-4d6a-a130-d4c15418d76c';
    } else {
      this.profilePhoto = this.user.profilePhoto.source;
    }
  }

  onConfirmVerification() {
    this.spinner.show(this.spinnerName);
    this.user.verificationInfo.isVerified = true;
    this.$db.ref.doc(this.user.id).update(this.user).then(res => {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'User profile successfuly verified.',
        showConfirmButton: false,
        timer: 1500
      }).then(() => {
        this.spinner.hide(this.spinnerName);
        location.reload();
      });
      this.activeModal.close('onConfirmVerification');
    }).catch(error => {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Failed to update document.',
        showConfirmButton: false,
        timer: 1500
      });
      console.error(error);
    });
  }

  onArchive() {
    this.spinner.show(this.spinnerName);
    this.$db.ref.doc(this.user.id).update({isDeleted: true}).then(res => {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'User profile successfuly archived.',
        showConfirmButton: false,
        timer: 1500
      }).then(() => {
        this.spinner.hide(this.spinnerName);
        location.reload();
      });
      this.activeModal.close('onArchive');
    }).catch(error => {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Failed to update document.',
        showConfirmButton: false,
        timer: 1500
      });
      console.error(error);
    });
  }

  onDisapprove() {
    this.spinner.show(this.spinnerName);
    const sendUserFailedVerificationEmailAPI = firebase.functions().httpsCallable('sendUserFailedVerificationEmail');
    sendUserFailedVerificationEmailAPI({userRecord: this.user}).then(res => {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Email notification sent to user.',
        showConfirmButton: false,
        timer: 1500
      }).then(() => {
        this.spinner.hide(this.spinnerName);
        location.reload();
      });
      this.activeModal.close('onDisapprove');
    }).catch(error => {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Failed to update document.',
        showConfirmButton: false,
        timer: 1500
      });
      console.error(error);
    });
  }

  closeModal() {
    this.activeModal.close('closed');
  }

}
