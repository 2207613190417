import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { RouterModule } from '@angular/router';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerModule } from "ngx-spinner";
import { UserDetailModalComponent } from './user-detail-modal/user-detail-modal.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ProductCardComponent } from './product-card/product-card.component';
import { LendingCardComponent } from './lending-card/lending-card.component';
import { BorrowingCardComponent } from './borrowing-card/borrowing-card.component';
import { SecurityDepositNamePipe } from './pipes/security-deposit-name.pipe';
import { ProductRatingsComponent } from './product-ratings/product-ratings.component';
import { ProductStatusComponent } from './product-status/product-status.component';
import { ProductDetailModalComponent } from './product-detail-modal/product-detail-modal.component';
import { DisplayAddressPipe } from './pipes/display-address.pipe';
import { AssignRiderModalComponent } from './assign-rider-modal/assign-rider-modal.component';
import { CityByProvinceFilterPipe } from './pipes/city-by-province-filter.pipe';
import { UpdateTrackerStatusModalComponent } from './update-tracker-status-modal/update-tracker-status-modal.component';
import { ItemTrackerStatusPipe } from './pipes/item-tracker-status.pipe';
import { RiderTransactionTypeComponent } from './rider-transaction-type/rider-transaction-type.component';
import { RiderTransactionTypeFilterPipe } from './pipes/rider-transaction-type-filter.pipe';
import { RiderTransactionStatusComponent } from './rider-transaction-status/rider-transaction-status.component';
import { DisplayFilterComponent } from './display-filter/display-filter.component';



@NgModule({
  declarations: [
    BreadcrumbsComponent,
    UserDetailModalComponent,
    ProductCardComponent,
    LendingCardComponent,
    BorrowingCardComponent,
    SecurityDepositNamePipe,
    ProductRatingsComponent,
    ProductStatusComponent,
    ProductDetailModalComponent,
    DisplayAddressPipe,
    AssignRiderModalComponent,
    CityByProvinceFilterPipe,
    UpdateTrackerStatusModalComponent,
    ItemTrackerStatusPipe,
    RiderTransactionTypeComponent,
    RiderTransactionTypeFilterPipe,
    RiderTransactionStatusComponent,
    DisplayFilterComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    DataTablesModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    FormsModule
  ],
  entryComponents: [UserDetailModalComponent, ProductDetailModalComponent, AssignRiderModalComponent, UpdateTrackerStatusModalComponent],
  exports: [
    DataTablesModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    BreadcrumbsComponent,
    UserDetailModalComponent,
    ProductCardComponent,
    LendingCardComponent,
    BorrowingCardComponent,
    ProductDetailModalComponent,
    DisplayAddressPipe,
    AssignRiderModalComponent,
    FormsModule,
    CityByProvinceFilterPipe,
    UpdateTrackerStatusModalComponent,
    ItemTrackerStatusPipe,
    RiderTransactionTypeComponent,
    RiderTransactionTypeFilterPipe,
    RiderTransactionStatusComponent,
    DisplayFilterComponent
  ]
})
export class SharedModule { }
