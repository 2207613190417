import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { LendiRiderRecord, environment } from 'projects/lendi-business/src/public-api';
import { LendiEmployeeRecord } from 'projects/lendi-business/src/public-api';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import Swal from 'sweetalert2';
import { promise } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  currentUser: BehaviorSubject<LendiEmployeeRecord | LendiRiderRecord>;

  constructor(
    private router: Router,
    private afStore: AngularFirestore,
    private afAuth: AngularFireAuth) {
      this.currentUser = new BehaviorSubject<LendiEmployeeRecord | LendiRiderRecord>(null);
    }

    get refEmployee() {
      return this.afStore.collection(environment.collections.employeeProfiles);
    }

    get refRider() {
      return this.afStore.collection(environment.collections.riderProfiles);
    }

    runUserProfileSubscriber() {
      this.afAuth.authState.subscribe(user => {

        if (user) {

          Promise.all([
            this.refEmployee.doc(user.uid).get().toPromise().then(res => {
              if (res.exists) {
                return res.data() as LendiEmployeeRecord;
              } else {
                return undefined;
              }
            }),
            this.refRider.doc(user.uid).get().toPromise().then(res => {
              if (res.exists) {
                return res.data() as LendiRiderRecord;
              } else {
                return undefined;
              }
            })
          ]).then(res => {
            if (res[0]) {
              this.currentUser.next(res[0]);
            } else if (res[1]) {
              this.currentUser.next(res[1]);
            } else {
              // registered public user
              this.currentUser.next(undefined);
              this.afAuth.auth.signOut().finally(() => {
                this.router.navigate(['auth', 'login']);
                Swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: 'Invalid User Login.',
                  showConfirmButton: false,
                  timer: 1000
                });
              });
            }
          }).catch(err => {
            console.error(err);
          });

        } else {
          this.currentUser.next(null);
        }

      });
    }


}
