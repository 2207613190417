import { Component, OnInit, Input } from '@angular/core';
import { ItemTrackerStatus } from 'src/class/borrow-request.model';

@Component({
  selector: 'app-rider-transaction-type',
  templateUrl: './rider-transaction-type.component.html',
  styleUrls: ['./rider-transaction-type.component.css']
})
export class RiderTransactionTypeComponent implements OnInit {

  @Input() status: ItemTrackerStatus;

  constructor() { }

  ngOnInit() {
  }

}
