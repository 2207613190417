import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment, VerificationStatus } from 'projects/lendi-business/src/public-api';
import { BorrowRequestStatus, LenderPayoutStatus, ItemTrackerStatus } from 'src/class/borrow-request.model';

@Injectable({
  providedIn: 'root'
})
export class BorrowRequestsService {

  constructor(private $db: AngularFirestore) {}

  get ref() {
    return this.$db.collection(environment.collections.borrowRequests);
  }

  getRefBorrowRequestByUid(uid: string) {
    return this.$db.collection(environment.collections.borrowRequests , ref => ref
      .where('uid', '==', uid)
    );
  }

  getRefLendingRequestByUid(uid: string) {
    return this.$db.collection(environment.collections.borrowRequests , ref => ref
      .where('product.uid', '==', uid)
    );
  }

  getBorrowRequestReadyForPayout() {
    return this.$db.collection(environment.collections.borrowRequests,
      ref => ref
      .orderBy('createdAt', 'desc')
      .where('isDeleted', '==', false)
      .where('isPaid', '==', true)
      .where('itemTrackerStatus', '==', ItemTrackerStatus.DeliverySuccessfulToLender)
    );
  }

  getPickupFromLenderToBorrower() {
    return this.$db.collection(environment.collections.borrowRequests,
      ref => ref
      .where('isDeleted', '==', false)
      .where('isPaid', '==', true)
      .where('verificationStatus', '==', VerificationStatus.Approved)
      .where('status', 'in', [BorrowRequestStatus.Pending, BorrowRequestStatus.Current])
      .where('itemTrackerStatus', '<', ItemTrackerStatus.AssignedRiderFromLendertoBorrower)
      .orderBy('itemTrackerStatus', 'asc')
      .orderBy('createdAt', 'desc'));
  }

  getPickupFromBorrowerToLender() {
    return this.$db.collection(environment.collections.borrowRequests,
      ref => ref
      .where('isDeleted', '==', false)
      .where('isPaid', '==', true)
      .where('verificationStatus', '==', VerificationStatus.Approved)
      .where('status', 'in', [BorrowRequestStatus.Pending, BorrowRequestStatus.Current])
      .where('itemTrackerStatus', '==', ItemTrackerStatus.DeliverySuccessfulToBorrower)
      .orderBy('createdAt', 'desc'));
  }

  getAllIncompleteRequestByProductId(productId: string) {
    return this.$db.collection(environment.collections.borrowRequests,
      ref => ref
      .where('isDeleted', '==', false)
      .where('product.id', '==', productId)
      .where('status', 'in', [BorrowRequestStatus.Pending, BorrowRequestStatus.Current])
      .orderBy('createdAt', 'desc')
    ).get();
  }

  getAllByBorrowerUserId(uid: string, limit: number = 10) {
    return this.$db.collection(environment.collections.borrowRequests,
      ref => ref
      .where('isDeleted', '==', false)
      .where('uid', '==', uid)
      .limit(limit)
      .orderBy('createdAt', 'desc')
    ).get();
  }

  getByBorrowRequestStatusByBorrowerUserId(uid: string, status: BorrowRequestStatus, limit: number = 10) {
    return this.$db.collection(environment.collections.borrowRequests,
      ref => ref
      .where('isDeleted', '==', false)
      .where('uid', '==', uid)
      .where('status', '==', status)
      .limit(limit)
      .orderBy('createdAt', 'desc')
    ).get();
  }

  getByLenderPayoutStatusByLenderUserId(uid: string, status: LenderPayoutStatus, limit: number = 10) {
    return this.$db.collection(environment.collections.borrowRequests,
      ref => ref
      .where('isDeleted', '==', false)
      .where('product.uid', '==', uid)
      .where('lenderPayoutStatus', '==', status)
      .limit(limit)
      .orderBy('createdAt', 'desc')
    ).get();
  }

}
