import { Component, OnInit, OnDestroy } from '@angular/core';
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SpinnerService } from 'src/app/services/spinner.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RidersService } from 'src/app/services/riders.service';
import { LendiRiderRecord } from 'projects/lendi-business/src/public-api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-rider-list',
  templateUrl: './rider-list.component.html',
  styleUrls: ['./rider-list.component.css']
})
export class RiderListComponent implements OnInit, OnDestroy {

  spinnerName = 'RiderListComponent';
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  riderItems: LendiRiderRecord[] = [];

  constructor(
    private $db: RidersService,
    private spinner: SpinnerService,
    private modalService: NgbModal,
    private router: Router) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };

    this.spinner.show(this.spinnerName);
    this.$db.refActive.valueChanges().pipe(first()).subscribe(riders => {
      this.riderItems = riders as any;
      this.dtTrigger.next();
      this.spinner.hide(this.spinnerName);
    });
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  onViewEdit(lendiRiderRecord: LendiRiderRecord) {
    this.router.navigate(['rider-database', lendiRiderRecord.id, 'detail'], {
      state: {
        item: lendiRiderRecord
      }
    });
  }

}
