import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { LoginGuard } from './auth/login.guard';


const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'item-verification',
    loadChildren: () => import('./item-verification/item-verification.module').then(m => m.ItemVerificationModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'pending-pickups',
    loadChildren: () => import('./pickups/pickups.module').then(m => m.PickupsModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'item-status',
    loadChildren: () => import('./item-tracker/item-tracker.module').then(m => m.ItemTrackerModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'user-database',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'user-verification',
    loadChildren: () => import('./user-verification/user-verification.module').then(m => m.UserVerificationModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'employee-database',
    loadChildren: () => import('./employee/employee.module').then(m => m.EmployeeModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'rider-database',
    loadChildren: () => import('./rider/rider.module').then(m => m.RiderModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'security-deposit-refund',
    loadChildren: () => import('./security-deposit/security-deposit.module').then(m => m.SecurityDepositModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'promo-banners',
    loadChildren: () => import('./promo-banners/promo-banners.module').then(m => m.PromoBannersModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'lendi-credits',
    loadChildren: () => import('./lendi-credits/lendi-credits.module').then(m => m.LendiCreditsModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'borrow-requests',
    loadChildren: () => import('./borrow-requests/borrow-requests.module').then(m => m.BorrowRequestsModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'transactions',
    loadChildren: () => import('./transactions/transactions.module').then(m => m.TransactionsModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    canActivate: [ LoginGuard ]
  },
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
