import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PickupsRoutingModule } from './pickups-routing.module';
import { LenderToBorrowerListComponent } from './lender-to-borrower-list/lender-to-borrower-list.component';
import { BorrowerToLenderListComponent } from './borrower-to-lender-list/borrower-to-lender-list.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    LenderToBorrowerListComponent,
    BorrowerToLenderListComponent
  ],
  imports: [
    CommonModule,
    PickupsRoutingModule,
    SharedModule
  ],
  exports: [
    LenderToBorrowerListComponent,
    BorrowerToLenderListComponent
  ]
})
export class PickupsModule { }
