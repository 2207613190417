import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SpinnerService } from 'src/app/services/spinner.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BorrowRequestsService } from 'src/app/services/borrow-requests.service';
import { UpdateTrackerStatusModalComponent } from 'src/app/shared/update-tracker-status-modal/update-tracker-status-modal.component';
import { RiderTransactionService } from 'src/app/services/rider-transaction.service';
import { LendiUserRecord, BorrowRequestRecord, unique, RiderTransactionRecord } from 'projects/lendi-business/src/public-api';

@Component({
  selector: 'app-item-tracker-list',
  templateUrl: './item-tracker-list.component.html',
  styleUrls: ['./item-tracker-list.component.css']
})
export class ItemTrackerListComponent implements OnInit, OnDestroy {

  spinnerName = 'ItemTrackerListComponent';
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  borrowRequests: BorrowRequestRecord[] = [];
  userProfiles: LendiUserRecord[] = [];
  riderTransactions: RiderTransactionRecord[] = [];

  constructor(
    private $db: BorrowRequestsService,
    private spinner: SpinnerService,
    private modalService: NgbModal,
    private $dbUsers: UsersService,
    private $dbRiderTransactionService: RiderTransactionService) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };

    this.spinner.show(this.spinnerName);
    this.$dbRiderTransactionService.getActiveTransactions()
    .valueChanges().pipe(first()).subscribe(riderTransactions => {
      this.riderTransactions = riderTransactions as any;

      const borrowerUids = this.riderTransactions.map(rt => rt.borrowRequest.uid);
      const lenderUids = this.riderTransactions.map(rt => rt.borrowRequest.product.uid);
      const uids = unique([].concat(borrowerUids, lenderUids));

      if (uids.length) {
        this.$dbUsers.getUserProfiles(uids).toPromise().then(users => {
          this.userProfiles = users.docs.map(d => d.data() as LendiUserRecord);
        }).finally(() => {
          this.spinner.hide(this.spinnerName);
        });
      } else {
        this.spinner.hide(this.spinnerName);
      }

      this.dtTrigger.next();
    });
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  updateStatus(riderTransaction: RiderTransactionRecord) {
    const modalRef = this.modalService.open(UpdateTrackerStatusModalComponent, { size: 'md' });
    modalRef.componentInstance.riderTransaction = riderTransaction;
    modalRef.componentInstance.borrowerProfile = this.userProfiles.find(u => u.id === riderTransaction.borrowRequest.uid);
    modalRef.componentInstance.lenderProfile = this.userProfiles.find(u => u.id === riderTransaction.borrowRequest.product.uid);
  }

  getUserName(uid: string) {
    const userProfile = this.userProfiles.find(u => u.id === uid);
    if (userProfile === undefined) { return ''; }

    return `${userProfile.profile.firstname} ${userProfile.profile.lastname}`;
  }

}
