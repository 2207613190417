import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Router, ActivatedRoute } from '@angular/router';
import { RidersService } from 'src/app/services/riders.service';
import { LendiRiderRecord, CivilStatus } from 'projects/lendi-business/src/public-api';
import { setErrorMessage } from 'src/app/shared/validators/validation-messages';
import Swal from 'sweetalert2';
import * as firebase from 'firebase';
import { PageMode } from 'src/class/page-mode.enum';
import { LendiPhoto } from 'src/class/image.model';
import { compare, City, Province } from 'src/class/app-settings.model';
import { PlaceholderAppReferenceService } from 'src/app/services/placeholder-app-reference.service';
import { environment } from 'projects/lendi-business/src/public-api';

declare var $: any;

@Component({
  selector: 'app-rider-detail',
  templateUrl: './rider-detail.component.html',
  styleUrls: ['./rider-detail.component.css']
})
export class RiderDetailComponent implements OnInit {

  spinnerName = 'RiderDetailComponent';
  lendiRiderRecord: LendiRiderRecord;
  lendiRiderForm: FormGroup = this.fb.group({
    profile: this.fb.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      middlename: [''],
      gender: ['Male', [Validators.required]],
      civilStatus: [CivilStatus.Single, [Validators.required]],
      birthdate: ['', [Validators.required]],
      mobile: ['', [Validators.required]],
      email: ['', [Validators.email]]
    }),
    profileAddress: this.fb.group({
      streetAddress: ['', [Validators.required, Validators.maxLength(255)]],
      barangay: ['', [Validators.required, Validators.maxLength(255)]],
      region: [null, [Validators.required, Validators.maxLength(255)]],
      city: [null, [Validators.required, Validators.maxLength(255)]],
      postalCode: ['', [Validators.required, Validators.maxLength(4)]]
    }),
    vehicleInfo: this.fb.group({
      brand: ['', [Validators.required]],
      model: ['', [Validators.required]],
      plateNumber: ['', [Validators.required]]
    }),
    accountDetails: this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    })
  });
  formErrors: { [key: string]: string };
  profilePhotoUrl: string;
  selectedFile: File = null;
  pageMode = PageMode.New;
  provinces: Province[] =  environment.provinces;
  cities: City[] = environment.cities;

  constructor(
    private fb: FormBuilder,
    private spinner: SpinnerService,
    private router: Router,
    private activeRouter: ActivatedRoute,
    private $db: RidersService,
    private $dbDummyApp: PlaceholderAppReferenceService
  ) { }

  get city() {
    return this.lendiRiderForm.get('profileAddress.city');
  }
  get region() {
    return this.lendiRiderForm.get('profileAddress.region');
  }

  ngOnInit() {
    this.cities = this.cities.sort((a: City, b: City) => compare(a.description, b.description));
    this.setUpForm();
    this.setErrorSubscriptions();
    this.setDefaultLendiRiderRecord();
  }

  setUpForm() {
    $('#birthdate').datepicker({
      uiLibrary: 'bootstrap',
      change: e => {
        this.lendiRiderForm.get('profile.birthdate').setValue($(e.target).val());
      }
    });

    this.formErrors = {
      firstname: '',
      lastname: '',
      birthdate: '',
      mobile: '',
      gender: '',
      civilStatus: '',
      streetAddress: '',
      barangay: '',
      region: '',
      city: '',
      postalCode: '',
      brand: '',
      model: '',
      plateNumber: '',
      email: '',
      password: ''
    };
  }

  setDefaultLendiRiderRecord() {
    const idFromRoute = this.activeRouter.snapshot.paramMap.get('id');
    this.lendiRiderRecord = window.history.state.item;

    if (this.lendiRiderRecord === undefined && idFromRoute === null) {
      this.lendiRiderRecord = {
        id: this.$db.ref.ref.doc().id,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        isActive: true,
        isDeleted: false,
        profile: null,
        profileAddress: null,
        profilePhoto: null,
        vehicleInfo: null,
        accountDetails: null
      };
      this.pageMode = PageMode.New;
      return;
    }

    this.pageMode = PageMode.Edit;
    if (this.lendiRiderRecord === undefined && idFromRoute !== null) {
      this.spinner.show(this.spinnerName);
      this.$db.ref.doc(idFromRoute).get().toPromise().then(res => {
        this.lendiRiderRecord = res.data() as LendiRiderRecord;
        this.setFormValues(this.lendiRiderRecord);
      }).catch(err => {
        console.error(err);
        this.back();
      }).finally(() => {
        this.spinner.hide(this.spinnerName);
      });
    } else {
      this.setFormValues(this.lendiRiderRecord);
    }
  }

  setProfilePhoto() {
    if (this.lendiRiderRecord.profilePhoto) {
      if (this.lendiRiderRecord.profilePhoto.source) {
        this.profilePhotoUrl = this.lendiRiderRecord.profilePhoto.source;
        return;
      }
    }

    this.profilePhotoUrl = undefined;
  }

  setFormValues(lendiRiderRecord: LendiRiderRecord) {
    this.lendiRiderForm.get('profile').setValue(lendiRiderRecord.profile);
    this.lendiRiderForm.get('profileAddress').setValue(lendiRiderRecord.profileAddress);
    this.lendiRiderForm.get('vehicleInfo').setValue(lendiRiderRecord.vehicleInfo);
    this.lendiRiderForm.get('accountDetails').setValue(lendiRiderRecord.accountDetails);
    this.setProfilePhoto();

    if (this.pageMode !== PageMode.New) {
      this.lendiRiderForm.get('accountDetails').disable();
    }
  }

  setErrorSubscriptions() {
    const profileControls = Object.keys((this.lendiRiderForm.get('profile') as FormGroup).controls);
    const profileAddressControls = Object.keys((this.lendiRiderForm.get('profileAddress') as FormGroup).controls);
    const vehicleInfoControls = Object.keys((this.lendiRiderForm.get('vehicleInfo') as FormGroup).controls);
    const accountDetailsControls = Object.keys((this.lendiRiderForm.get('accountDetails') as FormGroup).controls);

    Object.keys(this.formErrors).map((key) => {
      const inProfile = profileControls.find(k => k === key) ? 'profile' : '';
      const inProfileAddress = inProfile === '' && profileAddressControls.find(k => k === key) ? 'profileAddress' : '';
      const inVehicleInfo = inProfile === '' && inProfileAddress === '' && vehicleInfoControls.find(k => k === key) ? 'vehicleInfo' : '';
      const inAccountDetails = inProfile === '' && inProfileAddress === '' && inVehicleInfo === '' && accountDetailsControls.find(k => k === key) ? 'accountDetails' : '';
      const formGroupKey = inProfile + inProfileAddress + inVehicleInfo + inAccountDetails;

      this.lendiRiderForm.get(formGroupKey + '.' + key).valueChanges.subscribe(value => {
        this.formErrors[key] = setErrorMessage(this.lendiRiderForm.get(formGroupKey + '.' + key), key);
      });
    });
  }

  onSubmit() {
    if (this.lendiRiderForm.valid) {

      const lendiRiderFormValues = this.lendiRiderForm.getRawValue();
      this.lendiRiderRecord = Object.assign(this.lendiRiderRecord, {...lendiRiderFormValues});

      this.spinner.show(this.spinnerName);

      const successFunc = (res) => {

        const showModalThenRedirect = () => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Rider profile successfuly saved.',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.back();
          });
        };

        const hasUpload = this.saveProfilePhoto();
        if (hasUpload) {
          hasUpload.then(showModalThenRedirect).finally(finallyFunc);
        } else {
          showModalThenRedirect();
        }
      };

      const errorFunc = (err) => {
        console.error(err);
      };

      const finallyFunc = () => {
        this.spinner.hide(this.spinnerName);
      };

      if (this.pageMode === PageMode.New) {
        this.$dbDummyApp
        .createAccount(this.lendiRiderRecord.accountDetails.email, this.lendiRiderRecord.accountDetails.password)
        .then(id => {
          this.lendiRiderRecord.id = id;
          this.$db.ref.doc(this.lendiRiderRecord.id)
          .set(this.lendiRiderRecord)
          .then(successFunc).catch(errorFunc);
        }).catch(errorFunc);
      } else {
        this.$db.ref.doc(this.lendiRiderRecord.id)
        .update(this.lendiRiderRecord)
        .then(successFunc).catch(errorFunc);
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please fill up all the required fields.',
        showConfirmButton: false,
        timer: 1500
      });
    }
  }

  saveProfilePhoto() {
    if (this.selectedFile !== null) {
      return this.$db.uploadProfilePhoto(this.lendiRiderRecord.id, new LendiPhoto(this.selectedFile));
    }

    return false;
  }

  back() {
    this.router.navigate(['rider-database']);
  }

  preview(files) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    const reader = new FileReader();
    this.selectedFile = files[0];
    reader.readAsDataURL(files[0]);
    reader.onload = (event) => {
      this.profilePhotoUrl = reader.result as any;
    };
  }

}
