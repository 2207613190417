import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserVerificationRoutingModule } from './user-verification-routing.module';
import { UserVerificationListComponent } from './user-verification-list/user-verification-list.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [UserVerificationListComponent],
  imports: [
    CommonModule,
    UserVerificationRoutingModule,
    SharedModule
  ]
})
export class UserVerificationModule { }
