import { Component, OnInit, OnDestroy } from '@angular/core';
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SpinnerService } from 'src/app/services/spinner.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from 'src/app/services/product.service';
import { LendiProductRecord, unique, LendiUserRecord } from 'projects/lendi-business/src/public-api';
import { ProductDetailModalComponent } from 'src/app/shared/product-detail-modal/product-detail-modal.component';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-item-verification-list',
  templateUrl: './item-verification-list.component.html',
  styleUrls: ['./item-verification-list.component.css']
})
export class ItemVerificationListComponent implements OnInit, OnDestroy {

  spinnerName = 'ItemVerificationListComponent';
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  productRecords: LendiProductRecord[] = [];
  userProfiles: LendiUserRecord[] = [];

  constructor(private $db: ProductService, private $dbUsers: UsersService, private spinner: SpinnerService, private modalService: NgbModal) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };

    this.spinner.show(this.spinnerName);
    this.$db.getUnverifiedProducts().valueChanges().pipe(first()).subscribe(products => {
      this.productRecords = products as any;
      const uids = unique(this.productRecords.map((p: LendiProductRecord) => p.uid));

      if (uids.length) {
        this.$dbUsers.getUserProfiles(uids).toPromise().then(users => {
          this.userProfiles = users.docs.map(d => d.data() as LendiUserRecord);
        }).finally(() => {
          this.spinner.hide(this.spinnerName);
        });
      } else {
        this.spinner.hide(this.spinnerName);
      }

      this.dtTrigger.next();
    });
  }

  getUserName(uid: string) {
    const userProfile = this.userProfiles.find(u => u.id === uid);
    if (userProfile === undefined) { return ''; }

    return `${userProfile.profile.firstname} ${userProfile.profile.lastname}`;
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  openProductDetailModal(productRecord: LendiProductRecord) {
    const modalRef = this.modalService.open(ProductDetailModalComponent, { size: 'lg' });
    modalRef.componentInstance.userRecord = this.userProfiles.find(u => u.id === productRecord.uid);
    modalRef.componentInstance.productRecord = productRecord;
  }

}
