import { Component, OnInit, Input } from '@angular/core';
import { LendiProductRecord } from 'src/class/product.model';
import { ProductService } from 'src/app/services/product.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-user-products',
  templateUrl: './user-products.component.html',
  styleUrls: ['./user-products.component.css']
})
export class UserProductsComponent implements OnInit {

  @Input() userId: string;

  spinnerName = 'UserProductsComponent';
  allProducts: LendiProductRecord[] = [];
  forVerificationProducts: LendiProductRecord[] = [];
  availableProducts: LendiProductRecord[] = [];
  completedProducts: LendiProductRecord[] = [];
  cancelledProducts: LendiProductRecord[] = [];
  borrowedProducts: LendiProductRecord[] = [];

  constructor(private $dbProducts: ProductService, private spinner: SpinnerService) { }

  ngOnInit() {
    this.spinner.show(this.spinnerName);
    Promise.all([
      this.$dbProducts.getProductsByUserId(this.userId, 20)
      .toPromise().then(res => {
        this.allProducts = res.docs.map(d => d.data() as LendiProductRecord);
      }),
      this.$dbProducts.getProductsForVerificationByUserId(this.userId, 20)
      .toPromise().then(res => {
        this.forVerificationProducts = res.docs.map(d => d.data() as LendiProductRecord);
      }),
      this.$dbProducts.getProductsAvialableByUserId(this.userId, 20)
      .toPromise().then(res => {
        this.availableProducts = res.docs.map(d => d.data() as LendiProductRecord);
      }),
      this.$dbProducts.getProductsCompletedByUserId(this.userId, 20)
      .toPromise().then(res => {
        this.completedProducts = res.docs.map(d => d.data() as LendiProductRecord);
      }),
      this.$dbProducts.getProductsCancelledByUserId(this.userId, 20)
      .toPromise().then(res => {
        this.cancelledProducts = res.docs.map(d => d.data() as LendiProductRecord);
      }),
      this.$dbProducts.getProductsBorrowedByUserId(this.userId, 20)
      .toPromise().then(res => {
        this.borrowedProducts = res.docs.map(d => d.data() as LendiProductRecord);
      })
    ])
    .catch(err => {
      console.error(err);
    }).finally(() => {
      this.spinner.hide(this.spinnerName);
    });
  }

}
