import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerService } from 'src/app/services/spinner.service';
import Swal from 'sweetalert2';
import { ItemTrackerStatus, RiderTransactionRecord } from 'src/class/borrow-request.model';
import { RidersService } from 'src/app/services/riders.service';
import { LendiRiderRecord } from 'projects/lendi-business/src/public-api';
import { ItemTrackerService } from 'src/app/services/item-tracker.service';
import { RiderTransactionService } from 'src/app/services/rider-transaction.service';
import { LendiUserRecord } from 'projects/lendi-business/src/public-api';

@Component({
  selector: 'app-update-tracker-status-modal',
  templateUrl: './update-tracker-status-modal.component.html',
  styleUrls: ['./update-tracker-status-modal.component.css']
})
export class UpdateTrackerStatusModalComponent implements OnInit {

  @Input() riderTransaction: RiderTransactionRecord;
  @Input() borrowerProfile: LendiUserRecord;
  @Input() lenderProfile: LendiUserRecord;

  riderRecords: LendiRiderRecord[] = [];
  selectedRiderId: string = null;
  selectedStatus: ItemTrackerStatus = null;
  lenderToBorrowerStatus: ItemTrackerStatus[] = [];
  borrowerToLenderStatus: ItemTrackerStatus[] = [];

  spinnerName = 'AssignRiderModalComponent';

  constructor(
    public activeModal: NgbActiveModal,
    private spinner: SpinnerService,
    private $dbTracker: ItemTrackerService,
    private $dbRiders: RidersService,
    private $dbRiderTransaction: RiderTransactionService) {

      this.lenderToBorrowerStatus = [
        ItemTrackerStatus.RiderOnTheWayToLender,
        ItemTrackerStatus.FailedPickUpFromLender,
        ItemTrackerStatus.PickedUpFromLender,
        ItemTrackerStatus.FailedDeliveryToBorrower,
        ItemTrackerStatus.TransactionCancelled,
        ItemTrackerStatus.DeliverySuccessfulToBorrower
      ];
      this.borrowerToLenderStatus = [
        ItemTrackerStatus.RiderOnTheWayToBorrower,
        ItemTrackerStatus.FailedPickUpFromBorrower,
        ItemTrackerStatus.PickedUpFromBorrower,
        ItemTrackerStatus.FailedDeliveryToLender,
        ItemTrackerStatus.DeliverySuccessfulToLender
      ];
    }

  ngOnInit() {
    this.selectedStatus = this.lenderToBorrowerStatus.find(s => s === this.riderTransaction.status)
    || this.borrowerToLenderStatus.find(s => s === this.riderTransaction.status) ? this.riderTransaction.status : null;
  }

  formError() {
    Swal.fire({
      position: 'center',
      icon: 'error',
      title: 'Please input all the required fields.',
      showConfirmButton: false,
      timer: 1500
    });
  }

  successfulTransaction(res) {
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Borrow Request status successfuly updated.',
      showConfirmButton: false,
      timer: 1500
    }).then(() => {
      this.closeModal();
      location.reload();
    });
  }

  failedTransaction(err) {
    Swal.fire({
      position: 'center',
      icon: 'error',
      title: 'Something when wrong. Please contact support.',
      showConfirmButton: false,
      timer: 1500
    });
  }

  onAssign() {
    if (this.selectedStatus !== null) {

      this.spinner.show(this.spinnerName);
      this.$dbRiderTransaction.updateRiderTransaction(this.riderTransaction, this.selectedStatus)
      .then(this.successfulTransaction.bind(this))
      .catch(this.failedTransaction.bind(this)).finally(() => {
        this.spinner.hide(this.spinnerName);
      });

    } else {
      this.formError();
    }
  }

  onClose() {
    this.closeModal();
  }

  closeModal() {
    this.activeModal.close('closed');
  }

}
