import { BorrowRequestRecord, FirebaseMetaData, LendiProductRecord, LendiUserRecord, LendiUserProfileAddress } from 'projects/lendi-business/src/public-api';
import { LendiRiderRecord } from 'projects/lendi-business/src/public-api';

export enum BorrowRequestStatus {
  Pending,
  Current,
  Completed,
  Cancelled
}

export enum SecurityDepositStatus {
  Pending,
  Refunded,
  OnHold,
  Forfeited
}

export enum LenderPayoutStatus {
  Pending,
  Processing,
  Completed
}

export enum PaymentStatus {
  Pending,
  Successful,
  Failed
}

export interface Payment {
  status: PaymentStatus;
  cardDetails: CardDetails;
}

export interface CardDetails {
  cardName: string;
  cardNumber: string;
  cardExpiry: ExpiryDate;
  cardCode: number;
}

export interface ExpiryDate {
  expiryMonth: number;
  expiryYear: number;
}

export interface BorrowRequest {
  fromDate: string;
  fromTime: string;
  toDate: string;
  toTime: string;
  deliveryAddress: LendiUserProfileAddress;
}

export interface BorrowRequestFees {
  deliveryFee: number;
  securityDeposit: number;
  rentalFee: number;
  totalRentalFee: number; // days x rental fee
  totalPayment: number;
}

// export interface BorrowRequestRecord extends FirebaseMetaData {
//   uid: string;
//   product: LendiProductRecord;
//   borrowRequest: BorrowRequest;
//   payment: Payment;
//   fees: BorrowRequestFees;
//   status: BorrowRequestStatus;
//   securityDepositStatus: SecurityDepositStatus;
//   lenderPayoutStatus: LenderPayoutStatus;
//   itemTrackerStatus: ItemTrackerStatus;
//   itemTracker?: ItemTracker;
//   lenderToBorrowerRider?: LendiRiderRecord;
//   borrowerToLenderRider?: LendiRiderRecord;
// }

export interface BorrowRequestRecordWithUserProfile extends BorrowRequestRecord {
  lenderUserProfile: LendiUserRecord;
  borrowerUserProfile: LendiUserRecord;
}

export interface BorrowRequestState {
  borrowRequest: BorrowRequest;
  product: LendiProductRecord;
  productOwner: LendiUserRecord;
  deliveryFee: number;
}


export enum RiderTransctionType {
  LenderToBorrower,
  BowerToLender
}

export interface StatusSnapshot {
  status: ItemTrackerStatus;
  updatedAt: Date;
}

export interface RiderTransactionRecord extends FirebaseMetaData {
  rider: LendiRiderRecord;
  borrowRequest: BorrowRequestRecord;
  type: RiderTransctionType;
  status: ItemTrackerStatus;
  updatedAt: any;
  snapshot: StatusSnapshot[];
}


export enum ItemTrackerStatus {
  Pending,
  Processing,
  AssignedRiderFromLendertoBorrower,
  RiderOnTheWayToLender,
  FailedPickUpFromLender,
  PickedUpFromLender,
  FailedDeliveryToBorrower,
  DeliverySuccessfulToBorrower,
  AssignedRiderFromBorrowerToLender,
  RiderOnTheWayToBorrower,
  FailedPickUpFromBorrower,
  PickedUpFromBorrower,
  FailedDeliveryToLender,
  DeliverySuccessfulToLender,
  TransactionCancelled,
}

export interface ItemTracker extends FirebaseMetaData {
  itemTrackerStatus: ItemTrackerStatus;
  riderProfile?: LendiRiderRecord;
}

// export interface ItemTrackerRecord extends FirebaseMetaData {
//   itemTracker: ItemTracker;
//   itemTrackerSnapshots: ItemTracker[];
// }

// export interface AssignedRider {
//   dateModified: Date;
//   rider: LendiRiderRecord;
// }

// export interface AssignedRiderRecord extends FirebaseMetaData {
//   assignedRider: AssignedRider;
//   assignedRiderSnapshots: AssignedRider[];
// }
