import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SecurityDepositListComponent } from './security-deposit-list/security-deposit-list.component';


const routes: Routes = [
  { path: 'overview', component: SecurityDepositListComponent },
  { path: '', pathMatch: 'full', redirectTo: 'overview' }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SecurityDepositRoutingModule { }
