import { Component, OnInit, OnDestroy } from '@angular/core';
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SpinnerService } from 'src/app/services/spinner.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BorrowRequestsService } from 'src/app/services/borrow-requests.service';
import { UsersService } from 'src/app/services/users.service';
import { AssignRiderModalComponent } from 'src/app/shared/assign-rider-modal/assign-rider-modal.component';
import { LendiUserRecord, BorrowRequestRecord, unique, ItemTrackerStatus, RiderTransctionType } from 'projects/lendi-business/src/public-api';

@Component({
  selector: 'app-lender-to-borrower-list',
  templateUrl: './lender-to-borrower-list.component.html',
  styleUrls: ['./lender-to-borrower-list.component.css']
})
export class LenderToBorrowerListComponent implements OnInit, OnDestroy {

  spinnerName = 'LenderToBorrowerListComponent';
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  borrowRequests: BorrowRequestRecord[] = [];
  userProfiles: LendiUserRecord[] = [];

  constructor(
    private $db: BorrowRequestsService,
    private spinner: SpinnerService,
    private modalService: NgbModal,
    private $dbUsers: UsersService) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };

    this.spinner.show(this.spinnerName);
    this.$db.getPickupFromLenderToBorrower().valueChanges().pipe(first()).subscribe(borrowRequests => {
      this.borrowRequests = borrowRequests as any;
      const borrowerUids = this.borrowRequests.map(br => br.uid);
      const lenderUids = this.borrowRequests.map(br => br.product.uid);
      const uids = unique([].concat(borrowerUids, lenderUids));

      if (uids.length) {
        this.$dbUsers.getUserProfiles(uids).toPromise().then(users => {
          this.userProfiles = users.docs.map(d => d.data() as LendiUserRecord);
        }).finally(() => {
          this.spinner.hide(this.spinnerName);
        });
      } else {
        this.spinner.hide(this.spinnerName);
      }

      this.dtTrigger.next();
    });
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  assignRider(borrowRequest: BorrowRequestRecord) {
    const modalRef = this.modalService.open(AssignRiderModalComponent, { size: 'md' });
    modalRef.componentInstance.borrowRequest = borrowRequest;
    modalRef.componentInstance.type = RiderTransctionType.LenderToBorrower;
    modalRef.componentInstance.itemTrackerStatus = ItemTrackerStatus.AssignedRiderFromLendertoBorrower;
    modalRef.componentInstance.borrowerProfile = this.userProfiles.find(u => u.id === borrowRequest.uid);
    modalRef.componentInstance.lenderProfile = this.userProfiles.find(u => u.id === borrowRequest.product.uid);

    // modalRef.result.then(res => {
    //   if (res === 'onAssign') {
    //     this.dtTrigger.next();
    //   }
    // });
  }

  getUserName(uid: string) {
    const userProfile = this.userProfiles.find(u => u.id === uid);
    if (userProfile === undefined) { return ''; }

    return `${userProfile.profile.firstname} ${userProfile.profile.lastname}`;
  }

}
