import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-rider-home',
  templateUrl: './rider-home.component.html',
  styleUrls: ['./rider-home.component.css']
})
export class RiderHomeComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  onAdd() {
    this.router.navigate(['rider-database', 'new']);
  }

}
