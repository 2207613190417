import { Component, OnInit, Input } from '@angular/core';
import { RiderTransactionRecord, ItemTrackerStatus } from 'src/class/borrow-request.model';

@Component({
  selector: 'app-rider-transaction-status',
  templateUrl: './rider-transaction-status.component.html',
  styleUrls: ['./rider-transaction-status.component.css']
})
export class RiderTransactionStatusComponent implements OnInit {

  @Input() riderTransaction: RiderTransactionRecord;

  textClass = '';
  statustext = '';

  constructor() { }

  ngOnInit() {
    if ([
      ItemTrackerStatus.DeliverySuccessfulToBorrower,
      ItemTrackerStatus.DeliverySuccessfulToLender
    ].find(s => s === this.riderTransaction.status)) {
      this.statustext = 'Sucessful';
      this.textClass = 'text-success';
    }

    if ([
      ItemTrackerStatus.AssignedRiderFromBorrowerToLender,
      ItemTrackerStatus.AssignedRiderFromLendertoBorrower
    ].find(s => s === this.riderTransaction.status)) {
      this.statustext = 'Assigned';
      this.textClass = 'text-default';
    }


    if ([
      ItemTrackerStatus.PickedUpFromBorrower,
      ItemTrackerStatus.PickedUpFromLender,
      ItemTrackerStatus.RiderOnTheWayToBorrower,
      ItemTrackerStatus.RiderOnTheWayToLender
    ].find(s => s === this.riderTransaction.status)) {
      this.statustext = 'Picked Up';
      this.textClass = 'text-warning';
    }

    if ([
      ItemTrackerStatus.FailedDeliveryToBorrower,
      ItemTrackerStatus.FailedDeliveryToLender,
      ItemTrackerStatus.FailedPickUpFromBorrower,
      ItemTrackerStatus.FailedPickUpFromLender
    ].find(s => s === this.riderTransaction.status)) {
      this.statustext = 'Failed';
      this.textClass = 'text-danger';
    }
  }


}
