import { Component, OnInit, Input } from '@angular/core';
import { RiderTransactionRecord, LendiUserRecord } from 'projects/lendi-business/src/public-api';

@Component({
  selector: 'app-pickup-delivery-card',
  templateUrl: './pickup-delivery-card.component.html',
  styleUrls: ['./pickup-delivery-card.component.css']
})
export class PickupDeliveryCardComponent implements OnInit {

  @Input() riderTransaction: RiderTransactionRecord;
  @Input() lenderProfile: LendiUserRecord;
  @Input() borrowerProfile: LendiUserRecord;

  constructor() { }

  ngOnInit() {
  }

}
