import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'projects/lendi-business/src/public-api';
import { AngularFireStorage } from '@angular/fire/storage';
import { UploadTask } from '@angular/fire/storage/interfaces';
import { LendiPhoto } from 'src/class/image.model';
import { LendiUserRecord } from 'lendi-business/lib/types';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  userProfileUploadTask: UploadTask;
  loadedUserRecords: LendiUserRecord[] = [];
  uniqueUserIds: string[] = [];

  constructor(private $db: AngularFirestore, private $storage: AngularFireStorage) { }

  get ref() {
    return this.$db.collection(environment.collections.userProfiles);
  }

  getCachedUsers(uids: string[]) {
    const getCachedUsersPromise = new Promise((resolve, reject) => {

      //not yet cached
      const workableIds = uids.filter(id => {
        return this.uniqueUserIds.find(i => i === id) === undefined;
      });
      if (workableIds.length) {

        this.getUserProfiles(workableIds).toPromise().then(res => {
          const newRecords = res.docs.map(d => d.data() as LendiUserRecord);
          this.loadedUserRecords = this.loadedUserRecords.concat(newRecords);
          resolve(this.loadedUserRecords);
        }).catch(err => {
          console.error(err);
          reject(this.loadedUserRecords);
        });

      } else {
        resolve(this.loadedUserRecords);
      }

    });

    return getCachedUsersPromise;
  }

  getUserProfiles(uids: string[]) {
    return this.$db.collection(environment.collections.userProfiles,
      ref => ref.where('id', 'in', uids)).get();
  }

  uploadProfilePhoto(id: string, upload: LendiPhoto) {
    const uploadAndUpdate = new Promise((resolve, reject) => {
      this.userProfileUploadTask = this.$storage.storage
        .ref(`${environment.storagePath.userProfilePhotos}/${id}/${upload.file.name}`)
        .put(upload.file);

      return this.userProfileUploadTask.then(
        (res) => {
          return this.$storage.storage.ref(res.metadata.fullPath).getDownloadURL().then(url => {
            return this.ref.doc(id).update({profilePhoto: { source: url }}).then(updateResponse => {
              resolve(url);
              return updateResponse;
            });
          });
        }).catch(error => {
          console.error(error);
          reject(error);
          return false;
        });
    });

    return uploadAndUpdate;
  }

  getUnverifiedUsers() {
    return this.$db.collection(environment.collections.userProfiles,
      ref => ref
      .where('isDeleted', '==', false)
      .where('verificationInfo.isVerified', '==', false)
      .orderBy('createdAt', 'desc')
    );
  }

  getImageByFullPath(fullPath) {
    return this.$storage.storage.ref(fullPath).getDownloadURL().then((url) => {
      return url;
    }).catch(error => {
      console.error(error);
      return false;
    });
  }

}
