import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { SpinnerService } from 'src/app/services/spinner.service';
import { AngularFireAuth } from '@angular/fire/auth';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  year;
  signinForm: FormGroup;
  formErrors: { [key: string]: string };

  constructor(private fb: FormBuilder, private spinner: SpinnerService, private $afAuth: AngularFireAuth, private router: Router) { }

  get email() {
    return this.signinForm.get('email');
  }
  get password() {
    return this.signinForm.get('password');
  }

  ngOnInit() {
    this.year = new Date().getFullYear();

    this.signinForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password:  ['', [Validators.required, Validators.minLength(6)]]
    });

    this.formErrors = {
      email: '',
      password: ''
    };

    // this.email.valueChanges.pipe(debounceTime(1000)).subscribe(value => {
    //   this.formErrors.email = setErrorMessage(this.email, 'email');
    // });

    // this.password.valueChanges.subscribe(value => {
    //   this.formErrors.password = setErrorMessage(this.password, 'password');
    // });
  }

  login() {
    if (this.signinForm.valid) {
      this.spinner.show();
      this.$afAuth.auth.signInWithEmailAndPassword(this.email.value, this.password.value).then((res) => {
        this.router.navigate(['/dashboard']);
      }).catch((err) => {
        console.error(err);
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: err.message,
          showConfirmButton: false,
          timer: 3000
        });
      }).finally(() => {
        this.spinner.hide();
      });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please fill up all the required fields.',
        showConfirmButton: false,
        timer: 1500
      });
    }
  }

}
