import { Component, OnInit, Input } from '@angular/core';
import { LendiProductRecord } from 'src/class/product.model';

@Component({
  selector: 'app-product-ratings',
  templateUrl: './product-ratings.component.html',
  styleUrls: ['./product-ratings.component.css']
})
export class ProductRatingsComponent implements OnInit {

  @Input() productRecord: LendiProductRecord;

  constructor() { }

  ngOnInit() {
  }

}
