import { Injectable } from '@angular/core';
import { environment } from 'projects/lendi-business/src/public-api';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class PlaceholderAppReferenceService {

  dummyAppName = 'DummyReference';
  dummyApp: any;

  constructor() {
    if (firebase.apps.length === 1) {
      this.dummyApp = firebase.initializeApp(environment.firebaseConfig, this.dummyAppName);
    } else {
      this.dummyApp = firebase.app(this.dummyAppName);
    }
  }

  createAccount(email, password) {
    return this.dummyApp.auth()
    .createUserWithEmailAndPassword(email, password)
    .then((userAuth) => {
      const id = userAuth.user.uid + '';

      this.dummyApp.auth().signOut();

      return id;
    }).catch(err => {
      console.error(err);
    });
  }

  deleteAccount(email, password) {
    return this.dummyApp
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then((userAuth) => {
      userAuth.user.delete();
      this.dummyApp.auth().signOut();
    });
  }

}
