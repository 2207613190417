import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RiderHomeComponent } from './rider-home/rider-home.component';
import { RiderDetailComponent } from './rider-detail/rider-detail.component';


const routes: Routes = [
  { path: 'overview', component: RiderHomeComponent },
  { path: 'new', component: RiderDetailComponent },
  { path: ':id/detail', component: RiderDetailComponent },
  { path: '', pathMatch: 'full', redirectTo: 'overview' }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RiderRoutingModule { }
