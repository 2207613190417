import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SpinnerService } from 'src/app/services/spinner.service';
import { LendiUserRecord } from 'projects/lendi-business/src/public-api';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { ForArchiveValue } from 'src/app/shared/display-filter/display-filter.component';

declare var $: any;

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit, OnDestroy {

  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  spinnerName = 'UserListComponent';
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  userItems: LendiUserRecord[] = [];
  displayFilterValue = null;

  constructor(
    private $db: UsersService,
    private spinner: SpinnerService,
    private router: Router) {}

  ngOnInit() {
    this.displayFilterValue = this.router.url.indexOf('deletion-request') !== -1 ? ForArchiveValue : null;

    $.fn['dataTable'].ext.search.push((settings, data: LendiUserRecord, dataIndex) => {
      if (this.displayFilterValue === null) {
        return true;
      } else if (this.displayFilterValue === ForArchiveValue) {
        const user = this.userItems.find(u => u.id === data[0]);
        return user && user.deleteProfileRequestDate !== undefined && user.deleteDate === undefined && !user.isDeleted;
      } else if (this.displayFilterValue) {
        return !this.userItems.find(u => u.id === data[0]).isDeleted;
      } else {
        return this.userItems.find(u => u.id === data[0]).isDeleted;
      }
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };

    this.spinner.show(this.spinnerName);
    this.$db.ref.valueChanges().pipe(first()).subscribe(users => {
      this.userItems = users as any;
      this.dtTrigger.next();
      this.spinner.hide(this.spinnerName);
    });
  }

  ngOnDestroy() {
    $.fn['dataTable'].ext.search.pop();
    this.dtTrigger.unsubscribe();
  }

  onViewEdit(userRecord: LendiUserRecord) {
    this.router.navigate(['user-database', userRecord.id, 'detail'], {
      state: {
        item: userRecord
      }
    });
  }

  displayFilterChanged($event) {
    this.displayFilterValue = $event;
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

}
