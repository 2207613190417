import { Pipe, PipeTransform } from '@angular/core';
import { SecurityDepositStatus } from 'src/class/borrow-request.model';

@Pipe({
  name: 'securityDepositName'
})
export class SecurityDepositNamePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    switch (value) {
      case SecurityDepositStatus.Refunded:
        return 'Refunded';
      case SecurityDepositStatus.Forfeited:
        return 'Forfeited';
      //case SecurityDepositStatus.Processing:
      //  return 'Processing';
      case SecurityDepositStatus.Pending:
      default:
        return 'On-Hold';
    }
  }

}
