import { Component, OnInit, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { LendiUserRecord } from 'src/class/user.model';
import { UsersService } from 'src/app/services/users.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import Swal from 'sweetalert2';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-user-ratings-info',
  templateUrl: './user-ratings-info.component.html',
  styleUrls: ['./user-ratings-info.component.css']
})
export class UserRatingsInfoComponent implements OnInit, OnChanges {

  @Input() userRecord: LendiUserRecord;

  spinnerName = 'UserDetailComponent';

  constructor(private $userService: UsersService, private spinner: SpinnerService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.userRecord.currentValue) {
      this.userRecord.createdAt  = new firebase.firestore.Timestamp(
        this.userRecord.createdAt.seconds,
        this.userRecord.createdAt.nanoseconds
      );
    }
  }

  suspendUser() {
    Swal.fire({
      title: 'Suspend User Profile',
      icon: 'warning',
      html: '<p>Are you sure you want to suspend this user?</p>',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Yes',
      confirmButtonAriaLabel: 'Yes',
      cancelButtonText: 'No',
      cancelButtonAriaLabel: 'No',
    }).then(res => {
      if (res.value) {
        this.spinner.show(this.spinnerName);
        this.$userService.ref.doc(this.userRecord.id).update({isActive: false})
        .then(response => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'User profile successfuly suspended.',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            location.reload();
          });
        })
        .catch(err => {
          console.error(err);
        }).finally(() => {
          this.spinner.hide(this.spinnerName);
        });
      }
    });
  }

  reactivateUser() {
    Swal.fire({
      title: 'Reactivate User Profile',
      icon: 'warning',
      html: '<p>Are you sure you want to reactivate this user?</p>',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Yes',
      confirmButtonAriaLabel: 'Yes',
      cancelButtonText: 'No',
      cancelButtonAriaLabel: 'No',
    }).then(res => {
      if (res.value) {
        this.spinner.show(this.spinnerName);
        this.$userService.ref.doc(this.userRecord.id).update({isActive: true})
        .then(response => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'User profile successfuly reactivated.',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            location.reload();
          });
        })
        .catch(err => {
          console.error(err);
        }).finally(() => {
          this.spinner.hide(this.spinnerName);
        });
      }
    });
  }

}
