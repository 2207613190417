import { Component, OnInit, OnDestroy } from '@angular/core';
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SpinnerService } from 'src/app/services/spinner.service';
import { BorrowRequestsService } from 'src/app/services/borrow-requests.service';
import { BorrowRequestRecord } from 'projects/lendi-business/src/public-api';

@Component({
  selector: 'app-borrow-requests-list',
  templateUrl: './borrow-requests-list.component.html',
  styleUrls: ['./borrow-requests-list.component.css']
})
export class BorrowRequestsListComponent implements OnInit, OnDestroy {

  spinnerName = 'ItemTrackerListComponent';
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  borrowRequests: BorrowRequestRecord[] = [];

  constructor(
    private $db: BorrowRequestsService,
    private spinner: SpinnerService) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };

    this.spinner.show(this.spinnerName);
    this.$db.ref.valueChanges().pipe(first()).subscribe(docs => {
      this.borrowRequests = docs as any;
      this.dtTrigger.next();
      this.spinner.hide(this.spinnerName);
    });
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

}
