import { Component, OnInit, Input } from '@angular/core';
import { BorrowRequestStatus, SecurityDepositStatus } from 'src/class/borrow-request.model';
import { LendiUserRecord } from 'src/class/user.model';
import { UsersService } from 'src/app/services/users.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { BorrowRequestRecord } from 'projects/lendi-business/src/public-api';

@Component({
  selector: 'app-borrowing-card',
  templateUrl: './borrowing-card.component.html',
  styleUrls: ['./borrowing-card.component.css']
})
export class BorrowingCardComponent implements OnInit {

  @Input() borrowRequest: BorrowRequestRecord;
  @Input() displaySecurityDepositStatus: boolean;

  productOwner: LendiUserRecord;
  spinnerName: string;
  cardClass: string;
  securityDepositClass: string;

  constructor(private $dbUser: UsersService, private spinner: SpinnerService) { }

  ngOnInit() {
    this.cardClass = this.setCardClass();
    this.securityDepositClass = this.setSecurityDepositClass();
    this.spinnerName = this.borrowRequest.id;
    this.spinner.show(this.spinnerName);
    this.$dbUser.ref
    .doc(this.borrowRequest.product.uid)
    .get().toPromise().then(userRecord => {
      this.productOwner = userRecord.data() as LendiUserRecord;
      this.spinner.show(this.spinnerName);
    });
  }

  setCardClass() {
    switch (this.borrowRequest.status) {
      case BorrowRequestStatus.Pending:
        return 'spending-transaction';
      case BorrowRequestStatus.Completed:
        return 'completed-transaction';
      case BorrowRequestStatus.Cancelled:
        return 'completed-transaction';
      case BorrowRequestStatus.Current:
      default:
        return 'cash-in-transaction';
    }
  }

  setSecurityDepositClass() {
    switch (this.borrowRequest.securityDepositStatus) {
      case SecurityDepositStatus.Refunded:
        return 'transaction-refunded';
      case SecurityDepositStatus.Forfeited:
        return 'transaction-forfeited';
      //case SecurityDepositStatus.Pending:
      //  return 'transaction-processing-refund';
      case SecurityDepositStatus.Pending:
      default:
        return 'transaction-processing';
    }
  }

}
