export interface Province {
  id: number;
  description: string;
}

export interface City {
  id: number;
  description: string;
  prov_dcode: number;
}

export function compare(a, b) {
  // Use toUpperCase() to ignore character casing
  const valA = a.toUpperCase();
  const valB = b.toUpperCase();

  let comparison = 0;
  if (valA > valB) {
    comparison = 1;
  } else if (valA < valB) {
    comparison = -1;
  }
  return comparison;
}
