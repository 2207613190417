import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TitleCasePipe } from '@angular/common';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css']
})
export class BreadcrumbsComponent implements OnInit {

  crumbs: string[] = [];
  parentLink = '';
  default = 'Quick Stock';

  constructor(private router: Router) { }

  ngOnInit() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.setBreadCrumbs(event.url);
      });
  }

  setBreadCrumbs(rawUrl: string) {
    this.crumbs = rawUrl.length
      ? rawUrl
          .substring(1)
          .split('/')
          .map(t => decodeURIComponent(new TitleCasePipe().transform(t.trim().replace('-', ' '))))
      : [];

    if (this.crumbs.length) {
      this.parentLink = this.crumbs[0].toLowerCase();
    }
  }

}
